<template>
  <map-card title="Llamada SOS" v-if="isVisible()" :actions="actions">
    <template v-slot:body>
      <v-card
        flat
        class="d-flex flex-column fill-height"
        style="background-color: transparent"
        dark
      >
        <v-card-text class="flex-grow-1 overflow-y-auto">
          <template v-for="(msg, index) in sosMessages">
            <div :key="index" :class="{ 'd-flex flex-row-reverse': msg.me }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-hover>
                    <v-chip
                      :color="msg.me ? 'primary' : ''"
                      dark
                      style="height: auto; white-space: normal"
                      class="pa-4 mb-2"
                      v-on="on"
                    >
                      {{ msg.text }}
                      <sub class="ml-2" style="font-size: 0.5rem">{{ msg.time }}</sub>
                    </v-chip>
                  </v-hover>
                </template>
              </v-menu>
            </div>
          </template>
        </v-card-text>
        <v-card-text class="flex-shrink-1">
          <v-text-field
            v-model="newMessage"
            type="text"
            no-details
            outlined
            append-outer-icon="mdi-send"
            hide-details
            @keyup.enter="sendMessage(newMessage)"
          >
          </v-text-field>
        </v-card-text>
      </v-card>
    </template>
  </map-card>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
import motivosCad from '@/data/motivosReporteCad.json';
import Dialog from '../../dialog';
import Form from '../../form';

export default {
  components: {
    'map-card': () => import('@/components/MapCard.vue'),
  },
  computed: {
    ...mapGetters('sosMessages', ['getSosMessages']),
    ...mapState('map', ['sosActiveCall']),
    sosMessages() {
      const ret = [];
      if (this.getSosMessages?.length > 0) {
        this.getSosMessages.forEach((element) => {
          if (element?.emerCallId && element?.emerCallId === this.sosActiveCall?._id) {
            ret.push({
              text: element.message,
              time: moment(element.createdAt).format('HH:mm:ss'),
              me: element.sender === 'operator',
            });
          }
        });
      }
      return ret;
    },
  },
  methods: {
    ...mapActions('map', ['setActiveCall']),
    sendMessage(msg) {
      window.VMA.mapComponent.sendSosMessage(msg);
      this.newMessage = '';
    },
    async findAddress(latitude, longitude) {
      window.VMA.loading(true);
      try {
        const response = await axios.get(
          `/nominatim/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}&addressdetails=1&extratags=1&namedetails=1`,
          {
            timeout: 1500,
          }
        );
        // const response = {
        //   data: {
        //     place_id: 1794486,
        //     licence: 'Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright',
        //     osm_type: 'way',
        //     osm_id: 202249479,
        //     lat: '24.74820709411508',
        //     lon: '-107.4239889475718',
        //     place_rank: 26,
        //     category: 'highway',
        //     type: 'residential',
        //     importance: 0.10000999999999993,
        //     addresstype: 'road',
        //     name: 'Calle José Vasconcelos',
        //     display_name:
        //       'Calle José Vasconcelos, Finisterra, Culiacán, Culiacán Rosales, Culiacán, Sinaloa, 80194, México',
        //     address: {
        //       road: 'Calle José Vasconcelos',
        //       quarter: 'Finisterra',
        //       city: 'Culiacán',
        //       county: 'Culiacán',
        //       state: 'Sinaloa',
        //       'ISO3166-2-lvl4': 'MX-SIN',
        //       postcode: '80194',
        //       country: 'México',
        //       country_code: 'mx',
        //     },
        //     extratags: { maxspeed: '40' },
        //     namedetails: { name: 'Calle José Vasconcelos' },
        //     boundingbox: ['24.7481704', '24.7483404', '-107.4246235', '-107.4213261'],
        //   },
        // };
        const ext = {
          colonia: (
            response.data.address.neighbourhood
            || response.data.address.quarter
            || response.data.address.suburb
            || response.data.address.village
            || ''
          )
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase(),
          municipio: (response.data.address.county || response.data.address.city || '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase(),
          calle: (response.data.address.road || '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase(),
        };
        window.VMA.loading(false);
        return ext.calle + ', ' + ext.colonia + ', ' + ext.municipio;
      } catch (error) {
        window.VMA.loading(false);
        console.error(error.message || error.code);
        return ''
      }
    },
    isVisible() {
      return this.sosActiveCall?._id;
    },
  },
  data() {
    return {
      newMessage: '',
      messages: [],
      actions: [
        {
          icon: 'mdi-microphone',
          help: 'MICROFONO',
          action: (a) => {
            if (a.icon === 'mdi-microphone') {
              window.VMA.sosMeeting.meeting.unmuteMic();
              a.icon = 'mdi-microphone-off';
            } else {
              window.VMA.sosMeeting.meeting.muteMic();
              a.icon = 'mdi-microphone';
            }
          },
        },
        {
          icon: 'mdi-camera-flip',
          help: 'CAMBIAR CAMARA',
          action: () => {
            if (this.sosActiveCall && window.VMA.mapComponent.appClientWs) {
              console.log('EMIT');
              window.VMA.mapComponent.appClientWs.emit('set_camera', 'changeCamera');
            }
          },
        },
        {
          icon: 'mdi-file-document-edit-outline',
          help: 'GENERAR REPORTE',
          action: async () => {
            if (this.sosActiveCall && window.VMA.mapComponent.appClientWs) {
              console.log('GENERAR REPORTE');
              const latitude = this.sosActiveCall.lastPosition.latitude;
              const longitude = this.sosActiveCall.lastPosition.longitude;
              const address = await this.findAddress(latitude, longitude);

              const form = new Form({
                schema: {
                  reason: {
                    label: 'Motivo',
                    type: String,
                    options: motivosCad.map((m) => ({ id: m, name: m })),
                  },
                  description: {
                    label: 'Descripción',
                    type: String,
                    textarea: true,
                  },
                  _location: {
                    label: 'Ubicación del mapa',
                    type: String,
                    location: true,
                    fields: ['latitude', 'longitude'],
                    default: `${latitude}, ${longitude}`,
                    onChange: async (value, fields) => {
                      if (value) {
                        fields.address.value = await this.findAddress(latitude, longitude);
                      }
                    },
                  },
                  latitude: {
                    label: 'Latitud',
                    type: Number,
                    decimals: true,
                    optional: true,
                    visibility: false,
                  },
                  longitude: {
                    label: 'Longitud',
                    type: Number,
                    decimals: true,
                    optional: true,
                    visibility: false,
                  },
                  address: {
                    label: 'Dirección',
                    type: String,
                    textarea: true,
                    default: address,
                  },
                },
              });
              const dialog = new Dialog({
                title: 'Generar reporte',
                subtitle: '',
                actions: [
                  {
                    color: 'success',
                    help: 'Guardar',
                    icon: 'mdi-content-save',
                    action: () => {
                      if (form.hasErrors()) {
                        return;
                      }
                      const reportData = {
                        callId: this.sosActiveCall._id,
                        ...form.getItem(),
                      };
                      window.VMA.mapComponent.appClientWs.emit('report_incident', reportData);
                      window.VMA.loading(true);
                    },
                  },
                ],
              });
              window.VMA.reportSOSDialog = dialog;
              dialog.open();
              dialog.append(form);
            }
          },
        },
        {
          icon: 'mdi-phone-hangup',
          help: 'COLGAR LLAMADA',
          action: () => {
            console.log('Colgar llamada');
            if (this.sosActiveCall && window.VMA.mapComponent.appClientWs) {
              window.VMA.mapComponent.appClientWs.emit('end_call', this.sosActiveCall?._id);
            }
          },
        },
      ],
    };
  },
};
</script>
