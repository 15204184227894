const ol = window.ol

class IncidentHeatMapStore {
  constructor(map, source) {
    this.map = map;
    this.olSource = source;
  }

  // Método para agregar un nuevo feature
  addFeature(item, weight = 10) {
    const feature = new ol.Feature({
      geometry: new ol.geom.Point(ol.proj.fromLonLat([item.longitude, item.latitude])),
      weight,
    });
    feature.setId(item.id);
    this.olSource.addFeature(feature);
  }

  // Método para eliminar un feature por ID
  removeFeatureById(id) {
    const feature = this.olSource.getFeatureById(id);
    if (feature) {
      this.olSource.removeFeature(feature);
    }
  }
}

export default IncidentHeatMapStore
