export const eventsType = {
  allEvents: {
    id: 'allEvents',
    name: 'Todos los eventos',
  },
  commandResult: {
    id: 'commandResult',
    name: 'Resultado de comando',
  },
  deviceOnline: {
    id: 'deviceOnline',
    name: 'En linea',
  },
  deviceUnknown: {
    id: 'deviceUnknown',
    name: 'Desconocido',
  },
  deviceOffline: {
    id: 'deviceOffline',
    name: 'Fuera de linea',
  },
  deviceMoving: {
    id: 'deviceMoving',
    name: 'En movimiento',
  },
  deviceStopped: {
    id: 'deviceStopped',
    name: 'Detenido',
  },
  deviceOverspeed: {
    id: 'deviceOverspeed',
    name: 'Sobre el limite de velocidad',
  },
  deviceFuelDrop: {
    id: 'deviceFuelDrop',
    name: 'Fuel drop',
  },
  geofenceEnter: {
    id: 'geofenceEnter',
    name: 'Entró a geocerca',
  },
  geofenceExit: {
    id: 'geofenceExit',
    name: 'Salió de geocerca',
  },
  alarm: {
    id: 'alarm',
    name: 'Alarma',
  },
  ignitionOn: {
    id: 'ignitionOn',
    name: 'Encendido',
  },
  ignitionOff: {
    id: 'ignitionOff',
    name: 'Apagado',
  },
  maintenance: {
    id: 'maintenance',
    name: 'Mantenimiento',
  },
  textMessage: {
    id: 'textMessage',
    name: 'Mensaje de texto',
  },
  driverChanged: {
    id: 'driverChanged',
    name: 'Cambio de conductor'
  }
}

export const alarmTypes = {
  hardBraking: {
    id: 'hardBraking',
    name: 'FRENADO BRUSCO',
  },
  hardAcceleration: {
    id: 'hardAcceleration',
    name: 'ACELERACIÓN BRUSCA',
  },
}

export default { eventsType, alarmTypes }
