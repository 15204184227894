import axios from 'axios';

export const getAddress = async (lat, lon) => {
  const result = {
    calle: '',
    colonia: '',
    poblacion: '',
    municipio: '',
    cp: '',
    direccion: '',
  };
  try {
    const response = await axios.get(
      `/api/location/reverse?format=jsonv2&lat=${lat}&lon=${lon}&addressdetails=1&extratags=1&namedetails=1`,
      {
        timeout: 1500,
      }
    );
    const { data } = response;
    result.calle = data.address.road || 'DESCONOCIDO';
    result.colonia = data.address.neighbourhood
    || data.address.quarter
    || data.address.suburb
    || data.address.village
    || data.address.farm
    || 'DESCONOCIDO';
    result.poblacion = data.address.village || data.address.city || data.address.town || 'DESCONOCIDO';
    result.municipio = data.address.county || 'DESCONOCIDO';
    result.municipio = result.municipio
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
    result.cp = data.address.postcode || 'DESCONOCIDO';
    result.direccion = data.display_name || 'DESCONOCIDO';
  } catch (error) {
    console.log('nominatim error', error.message);
  }
  return result;
};
