import ceroVeinte from '@/assets/katrina/20.png'
import veinteCuarenta from '@/assets/katrina/40.png'
import cuarentaSesenta from '@/assets/katrina/60.png'
import sesentaOchenta from '@/assets/katrina/80.png'
import ochentaCien from '@/assets/katrina/100.png'
import presaLlena from '@/assets/katrina/presaLlena.png'

export default {
  20: ceroVeinte,
  40: veinteCuarenta,
  60: cuarentaSesenta,
  80: sesentaOchenta,
  100: ochentaCien,
  presaLlena
}
