import IncidentMovementEnd from '@/assets/map/incident_movement_end.svg';

const ol = window.ol;

class ExternalSensorRouteStore {
  constructor(map, source) {
    this.map = map;
    this.source = source;
    this.selectedItem = null;
    this.cleanMarkers();
  }

  cleanMarkers() {
    this.selectedItem = null;
    this.source.clear();
  }

  createRoute(item) {
    this.cleanMarkers();
    this.selectedItem = item;
    const positions = item.persons?.[0]?.positions || [];
    const route = new ol.Feature({
      geometry: new ol.geom.LineString(
        positions.map((p) => ol.proj.fromLonLat([p.longitude, p.latitude]))
      ),
    });
    route.setStyle(
      new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: '#00AE87',
          width: 5,
        }),
      })
    );
    this.source.addFeature(route);
    positions.forEach((position, index) => {
      if (index === 0) return;
      const marker = new ol.Feature({
        geometry: new ol.geom.Point(
          window.ol.proj.fromLonLat([position.longitude, position.latitude])
        ),
        name: index,
      });
      marker.setId('position__' + index);
      marker.set('record', { ...position, additionalData: item.additionalData, typeOfItem: `${item.systemName}-POSITION` });
      marker.setStyle(this._getStyle());
      this.source.addFeature(marker)
    });
  }

  _getStyle() {
    return new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.9],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: IncidentMovementEnd,
      }),
      // text: new ol.style.Text({
      //   text: '1',
      //   scale: 1,
      //   fill: new ol.style.Fill({
      //     color: '#fff',
      //   }),
      //   offsetY: 0,
      //   offsetX: 0,
      //   stroke: new ol.style.Stroke({
      //     color: '0',
      //     width: 3,
      //   }),
      // }),
    });
  }
}

export default ExternalSensorRouteStore;
