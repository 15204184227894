export const emergencySubTypes = [
  {
    idEmergencyType: '000',
    id: '00',
    name: 'NO IDENTIFICADA',
    idCNI: '00000',
  },
  {
    idEmergencyType: 101,
    id: '101010001',
    name: 'ACCIDENTE ACUÁTICO CON PERSONA(S) LESIONADA(S)',
    idCNI: 10101,
  },
  {
    idEmergencyType: 101,
    id: '101020001',
    name: 'ACCIDENTE DE AERONAVE CON PERSONAS LESIONADAS',
    idCNI: 10102,
  },
  {
    idEmergencyType: 101,
    id: '101030001',
    name: 'ACCIDENTE DE MOTOCICLETA CON PERSONA(S) LESIONADA(S)',
    idCNI: 10103,
  },
  {
    idEmergencyType: 101,
    id: '101040001',
    name: 'ACCIDENTE DE VEHÍCULO AUTOMOTOR CON PERSONA(S) LESIONADA(S)',
    idCNI: 10104,
  },
  {
    idEmergencyType: 101,
    id: '101050001',
    name: 'ACCIDENTE FERROVIARIO CON PERSONAS LESIONADAS',
    idCNI: 10105,
  },
  {
    idEmergencyType: 101,
    id: '101060001',
    name: 'ACCIDENTE FERROVIARIO CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10106,
  },
  {
    idEmergencyType: 101,
    id: '101070001',
    name: 'ACCIDENTE MÚLTIPLE CON PERSONA(S) LESIONADA(S)',
    idCNI: 10107,
  },
  {
    idEmergencyType: 101,
    id: '101080001',
    name: 'ACCIDENTE MÚLTIPLE CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10108,
  },
  {
    idEmergencyType: 101,
    id: '101090001',
    name: 'ACCIDENTE DE TRANSPORTE DE PASAJEROS CON PERSONA(S) LESIONADA(S)',
    idCNI: 10109,
  },
  {
    idEmergencyType: 101,
    id: '101100001',
    name: 'ACCIDENTE DE TRANSPORTE DE PASAJEROS CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10110,
  },
  {
    idEmergencyType: 101,
    id: '101110001',
    name: 'ACCIDENTE DE MOTOCICLETA CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10111,
  },
  {
    idEmergencyType: 101,
    id: '101120001',
    name: 'ACCIDENTE DE VEHÍCULO AUTOMOTOR CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10112,
  },
  {
    idEmergencyType: 101,
    id: '101130001',
    name: 'ACCIDENTE DE AERONAVE CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10113,
  },
  {
    idEmergencyType: 101,
    id: '101140001',
    name: 'ACCIDENTE DE EMBARCACIONES CON PERSONA(S) LESIONADA(S)',
    idCNI: 10114,
  },
  {
    idEmergencyType: 101,
    id: '101150001',
    name: 'ACCIDENTE DE EMBARCACIONES CON PERSONA(S) FALLECIDA(S)',
    idCNI: 10115,
  },
  {
    idEmergencyType: 101,
    id: '101160001',
    name: 'ATROPELLAMIENTO CON PERSONA(S) LESIONADA(S)',
    idCNI: 10116,
  },
  {
    idEmergencyType: 101,
    id: '101170001',
    name: 'ACCIDENTE ACUÁTICO CON PERSONA FALLECIDA',
    idCNI: 10117,
  },
  {
    idEmergencyType: 101,
    id: '101180001',
    name: 'ACCIDENTES CON MATERIALES PELIGROSOS',
    idCNI: 10118,
  },
  {
    idEmergencyType: 101,
    id: '101190001',
    name: 'ACCIDENTES CON MATERIALES RADIOACTIVOS',
    idCNI: 10119,
  },
  {
    idEmergencyType: 101,
    id: '101200001',
    name: 'ACCIDENTES CON RIESGO BIOLÓGICO INFECTOCONTAGIOSO',
    idCNI: 10120,
  },
  {
    idEmergencyType: 101,
    id: '101210001',
    name: 'OTROS ACCIDENTES CON PERSONAS LESIONADAS',
    idCNI: 10121,
  },
  {
    idEmergencyType: 101,
    id: '101220001',
    name: 'ACCIDENTE DE AUTOTRANSPORTE DE CARGA CON PERSONAS LESIONADAS',
    idCNI: 10122,
  },
  {
    idEmergencyType: 101,
    id: '101230001',
    name: 'ACCIDENTE DE AUTOTRANSPORTE DE CARGA CON PERSONAS FALLECIDAS',
    idCNI: 10123,
  },
  {
    idEmergencyType: 101,
    id: '101240001',
    name: 'ATROPELLAMIENTO CON PERSONAS FALLECIDAS',
    idCNI: 10124,
  },
  {
    idEmergencyType: 101,
    id: '101250001',
    name: 'PERSONAS FALLECIDAS POR ELECTROCUCIÓN',
    idCNI: 10125,
  },
  {
    idEmergencyType: 101,
    id: '101260001',
    name: 'PERSONAS FALLECIDAS POR QUEMADURAS',
    idCNI: 10126,
  },
  {
    idEmergencyType: 101,
    id: '101270001',
    name: 'ACCIDENTE DE VEHÍCULOS DE TRACCIÓN HUMANA CON LESIONADOS',
    idCNI: 10127,
  },
  {
    idEmergencyType: 102,
    id: '102010001',
    name: 'AHOGAMIENTO',
    idCNI: 10201,
  },
  {
    idEmergencyType: 102,
    id: '102020001',
    name: 'AMPUTACIÓN',
    idCNI: 10202,
  },
  {
    idEmergencyType: 102,
    id: '102030001',
    name: 'ASFIXIA',
    idCNI: 10203,
  },
  {
    idEmergencyType: 102,
    id: '102040001',
    name: 'PERSONA LESIONADA POR CAÍDA',
    idCNI: 10204,
  },
  {
    idEmergencyType: 102,
    id: '102050001',
    name: 'PERSONA LESIONADA POR CORRIENTE ELÉCTRICA',
    idCNI: 10205,
  },
  {
    idEmergencyType: 102,
    id: '102060001',
    name: 'PERSONA CON FRACTURA / TRAUMATISMO EN EXTREMIDADES',
    idCNI: 10206,
  },
  {
    idEmergencyType: 102,
    id: '102070001',
    name: 'HEMORRAGIA',
    idCNI: 10207,
  },
  {
    idEmergencyType: 102,
    id: '102080001',
    name: 'PERSONA LESIONADA POR ARMA BLANCA',
    idCNI: 10208,
  },
  {
    idEmergencyType: 102,
    id: '102090001',
    name: 'PERSONA LESIONADA POR PROYECTIL DE ARMA DE FUEGO',
    idCNI: 10209,
  },
  {
    idEmergencyType: 102,
    id: '102100001',
    name: 'MORDEDURA DE ANIMAL',
    idCNI: 10210,
  },
  {
    idEmergencyType: 102,
    id: '102110001',
    name: 'PERSONA LESIONADA POR QUEMADURAS',
    idCNI: 10211,
  },
  {
    idEmergencyType: 102,
    id: '102130001',
    name: 'TRAUMATISMO DE CRÁNEO',
    idCNI: 10213,
  },
  {
    idEmergencyType: 102,
    id: '102140001',
    name: 'TRAUMATISMO DE TÓRAX (PECHO Y ESPALDA)',
    idCNI: 10214,
  },
  {
    idEmergencyType: 102,
    id: '102150001',
    name: 'TRAUMATISMO ABDOMINAL',
    idCNI: 10215,
  },
  {
    idEmergencyType: 102,
    id: '102160001',
    name: 'TRAUMATISMO GENITAL Y/O URINARIO',
    idCNI: 10216,
  },
  {
    idEmergencyType: 102,
    id: '102170001',
    name: 'CONGELAMIENTO / PERSONA LESIONADA POR CONDICIONES AMBIENTALES',
    idCNI: 10217,
  },
  {
    idEmergencyType: 102,
    id: '102180001',
    name: 'HIPOTERMIA',
    idCNI: 10218,
  },
  {
    idEmergencyType: 102,
    id: '102190001',
    name: 'PERSONA FALLECIDA DE CAUSA TRAUMÁTICA',
    idCNI: 10219,
  },
  {
    idEmergencyType: 102,
    id: '102200001',
    name: 'OTROS INCIDENTES MÉDICOS TRAUMÁTICOS',
    idCNI: 10220,
  },
  {
    idEmergencyType: 102,
    id: '102210001',
    name: 'ATRAGANTAMIENTO',
    idCNI: 10221,
  },
  {
    idEmergencyType: 102,
    id: '102220001',
    name: 'PERSONA LESIONADA POR OBJETO CONTUNDENTE',
    idCNI: 10222,
  },
  {
    idEmergencyType: 102,
    id: '102230001',
    name: 'PERSONA FALLECIDA POR ASFIXIA',
    idCNI: 10223,
  },
  {
    idEmergencyType: 102,
    id: '102240001',
    name: 'DESHIDRATACIÓN',
    idCNI: 10224,
  },
  {
    idEmergencyType: 102,
    id: '102250001',
    name: 'PERSONA LESIONADA DURANTE ALTERACIÓN DEL ORDEN PÚBLICO O POR RIÑA',
    idCNI: 10225,
  },
  {
    idEmergencyType: 102,
    id: '102260001',
    name: 'PERSONAS FALLECIDAS POR PROYECTIL DE ARMA DE FUEGO',
    idCNI: 10226,
  },
  {
    idEmergencyType: 102,
    id: '102270001',
    name: 'PERSONAS FALLECIDAS POR ARMA BLANCA',
    idCNI: 10227,
  },
  {
    idEmergencyType: 102,
    id: '102280001',
    name: 'PERSONA FALLECIDA POR AHOGAMIENTO',
    idCNI: 10228,
  },
  {
    idEmergencyType: 102,
    id: '102290001',
    name: 'PERSONA CON GOLPE DE CALOR',
    idCNI: 10229,
  },
  {
    idEmergencyType: 102,
    id: '102300001',
    name: 'TENTATIVA DE SUICIDIO',
    idCNI: 10230,
  },
  {
    idEmergencyType: 103,
    id: '103010001',
    name: 'TRABAJO DE PARTO',
    idCNI: 10301,
  },
  {
    idEmergencyType: 103,
    id: '103020001',
    name: 'EMERGENCIA OBSTÉTRICA',
    idCNI: 10302,
  },
  {
    idEmergencyType: 103,
    id: '103030001',
    name: 'URGENCIA EN PACIENTE EMBARAZADA',
    idCNI: 10303,
  },
  {
    idEmergencyType: 103,
    id: '103040001',
    name: 'URGENCIA NEUROLÓGICA (EVENTO CEREBRAL VASCULAR)',
    idCNI: 10304,
  },
  {
    idEmergencyType: 103,
    id: '103050001',
    name: 'DIFICULTAD RESPIRATORIA / URGENCIA RESPIRATORIA',
    idCNI: 10305,
  },
  {
    idEmergencyType: 103,
    id: '103060001',
    name: 'INTOXICACIÓN ETÍLICA',
    idCNI: 10306,
  },
  {
    idEmergencyType: 103,
    id: '103070001',
    name: 'CONVULSIONES',
    idCNI: 10307,
  },
  {
    idEmergencyType: 103,
    id: '103080001',
    name: 'PERSONA INCONSCIENTE',
    idCNI: 10308,
  },
  {
    idEmergencyType: 103,
    id: '103090001',
    name: 'MORDEDURA O PICADURA POR ANIMAL DE PONZOÑA',
    idCNI: 10309,
  },
  {
    idEmergencyType: 103,
    id: '103100001',
    name: 'URGENCIA POR ENFERMEDAD GENERAL',
    idCNI: 10310,
  },
  {
    idEmergencyType: 103,
    id: '103110001',
    name: 'DOLOR ABDOMINAL / URGENCIA ABDOMINAL',
    idCNI: 10311,
  },
  {
    idEmergencyType: 103,
    id: '103120001',
    name: 'DESCOMPENSACIÓN DE LA DIABETES',
    idCNI: 10312,
  },
  {
    idEmergencyType: 103,
    id: '103130001',
    name: 'PARO CARDIORESPIRATORIO',
    idCNI: 10313,
  },
  {
    idEmergencyType: 103,
    id: '103140001',
    name: 'INFARTO / URGENCIA CARDIOLÓGICA',
    idCNI: 10314,
  },
  {
    idEmergencyType: 103,
    id: '103150001',
    name: 'INTOXICACIÓN / SOBREDOSIS / ENVENENAMIENTO POR SUSTANCIAS',
    idCNI: 10315,
  },
  {
    idEmergencyType: 103,
    id: '103160001',
    name: 'OTROS INCIDENTES MÉDICOS CLÍNICOS',
    idCNI: 10316,
  },
  {
    idEmergencyType: 103,
    id: '103170001',
    name: 'PERSONA FALLECIDA',
    idCNI: 10317,
  },
  {
    idEmergencyType: 103,
    id: '103180001',
    name: 'PERSONA EN CRISIS / APOYO PSICOLÓGICO',
    idCNI: 10318,
  },
  {
    idEmergencyType: 103,
    id: '103190001',
    name: 'EPIDEMIAS',
    idCNI: 10319,
  },
  {
    idEmergencyType: 103,
    id: '103190099',
    name: 'ORIENTACIÓN COVID19',
    idCNI: 10319,
  },
  {
    idEmergencyType: 103,
    id: '103200001',
    name: 'PERSONA FALLECIDA DE CAUSA PATOLÓGICA',
    idCNI: 10320,
  },
  {
    idEmergencyType: 103,
    id: '103210001',
    name: 'PERSONA TIRADA EN LA VÍA PÚBLICA CON HUELLAS DE VIOLENCIA',
    idCNI: 10321,
  },
  {
    idEmergencyType: 201,
    id: '201010001',
    name: 'ACCIDENTE FERROVIARIO SIN PERSONAS LESIONADAS',
    idCNI: 20101,
  },
  {
    idEmergencyType: 201,
    id: '201020001',
    name: 'ALMACENAMIENTO ILÍCITO DE SUSTANCIAS PELIGROSAS',
    idCNI: 20102,
  },
  {
    idEmergencyType: 201,
    id: '201030001',
    name: 'ANIMAL PELIGROSO',
    idCNI: 20103,
  },
  {
    idEmergencyType: 201,
    id: '201040001',
    name: 'ANIMAL SUELTO',
    idCNI: 20104,
  },
  {
    idEmergencyType: 201,
    id: '201050001',
    name: 'FUGAS Y DERRAMES EN ESCUELA',
    idCNI: 20105,
  },
  {
    idEmergencyType: 201,
    id: '201060001',
    name: 'DESBORDAMIENTO DE CUERPO DE AGUA',
    idCNI: 20106,
  },
  {
    idEmergencyType: 201,
    id: '201070001',
    name: 'EXPLOSIÓN',
    idCNI: 20107,
  },
  {
    idEmergencyType: 201,
    id: '201080001',
    name: 'FUGAS Y DERRAMES DE SUSTANCIAS QUÍMICAS',
    idCNI: 20108,
  },
  {
    idEmergencyType: 201,
    id: '201090001',
    name: 'HUNDIMIENTOS / AGRIETAMIENTOS / INESTABILIDAD DEL TERRENO-LADERA',
    idCNI: 20109,
  },
  {
    idEmergencyType: 201,
    id: '201100001',
    name: 'GASES TÓXICOS',
    idCNI: 20110,
  },
  {
    idEmergencyType: 201,
    id: '201110001',
    name: 'OLORES FÉTIDOS',
    idCNI: 20111,
  },
  {
    idEmergencyType: 201,
    id: '201120001',
    name: 'MATERIALES PELIGROSOS O RADIOACTIVOS (EXPOSICIÓN)',
    idCNI: 20112,
  },
  {
    idEmergencyType: 201,
    id: '201130001',
    name: 'CAÍDA O INCLINACIÓN PELIGROSA DE ANUNCIO O ESPECTACULAR',
    idCNI: 20113,
  },
  {
    idEmergencyType: 201,
    id: '201140001',
    name: 'TRANSPORTE NEGLIGENTE O CLANDESTINO DE SUSTANCIAS PELIGROSAS',
    idCNI: 20114,
  },
  {
    idEmergencyType: 201,
    id: '201150001',
    name: 'FUGA DE GAS',
    idCNI: 20115,
  },
  {
    idEmergencyType: 202,
    id: '202010001',
    name: 'CONTAMINACIÓN DE SUELO, AIRE Y AGUA',
    idCNI: 20201,
  },
  {
    idEmergencyType: 202,
    id: '202020001',
    name: 'DERRUMBES Y DESLAVES',
    idCNI: 20202,
  },
  {
    idEmergencyType: 202,
    id: '202030001',
    name: 'ENJAMBRE DE ABEJAS',
    idCNI: 20203,
  },
  {
    idEmergencyType: 202,
    id: '202040001',
    name: 'ERUPCIÓN O EMISIONES VOLCÁNICAS',
    idCNI: 20204,
  },
  {
    idEmergencyType: 202,
    id: '202050001',
    name: 'FRENTES FRÍOS, BAJAS TEMPERATURAS, NEVADAS Y HELADAS',
    idCNI: 20205,
  },
  {
    idEmergencyType: 202,
    id: '202060001',
    name: 'HURACANES',
    idCNI: 20206,
  },
  {
    idEmergencyType: 202,
    id: '202070001',
    name: 'INUNDACIONES',
    idCNI: 20207,
  },
  {
    idEmergencyType: 202,
    id: '202080001',
    name: 'PLAGAS',
    idCNI: 20208,
  },
  {
    idEmergencyType: 202,
    id: '202090001',
    name: 'SISMO',
    idCNI: 20209,
  },
  {
    idEmergencyType: 202,
    id: '202100001',
    name: 'ÁRBOL CAÍDO O POR CAER',
    idCNI: 20210,
  },
  {
    idEmergencyType: 202,
    id: '202110001',
    name: 'TORMENTAS DE GRANIZO / DE NIEVE',
    idCNI: 20211,
  },
  {
    idEmergencyType: 202,
    id: '202120001',
    name: 'TORNADOS',
    idCNI: 20212,
  },
  {
    idEmergencyType: 202,
    id: '202130001',
    name: 'TSUNAMI',
    idCNI: 20213,
  },
  {
    idEmergencyType: 202,
    id: '202140001',
    name: 'TORMENTA DE ARENA O POLVO',
    idCNI: 20214,
  },
  {
    idEmergencyType: 203,
    id: '203010001',
    name: 'INCENDIO DE CASA HABITACIÓN',
    idCNI: 20301,
  },
  {
    idEmergencyType: 203,
    id: '203020001',
    name: 'INCENDIO EN ESCUELA',
    idCNI: 20302,
  },
  {
    idEmergencyType: 203,
    id: '203030001',
    name: 'INCENDIO DE VEHÍCULO',
    idCNI: 20303,
  },
  {
    idEmergencyType: 203,
    id: '203040001',
    name: 'INCENDIO EN COMERCIO',
    idCNI: 20304,
  },
  {
    idEmergencyType: 203,
    id: '203050001',
    name: 'INCENDIO DE EDIFICIO',
    idCNI: 20305,
  },
  {
    idEmergencyType: 203,
    id: '203060001',
    name: 'INCENDIO A BORDO DE EMBARCACIÓN',
    idCNI: 20306,
  },
  {
    idEmergencyType: 203,
    id: '203070001',
    name: 'INCENDIO FORESTAL',
    idCNI: 20307,
  },
  {
    idEmergencyType: 203,
    id: '203080001',
    name: 'QUEMA URBANA',
    idCNI: 20308,
  },
  {
    idEmergencyType: 203,
    id: '203090001',
    name: 'QUEMA AGROPECUARIA',
    idCNI: 20309,
  },
  {
    idEmergencyType: 203,
    id: '203100001',
    name: 'INCENDIO DE FÁBRICA O INDUSTRIA',
    idCNI: 20310,
  },
  {
    idEmergencyType: 203,
    id: '203110001',
    name: 'OTROS INCENDIOS',
    idCNI: 20311,
  },
  {
    idEmergencyType: 203,
    id: '203120001',
    name: 'INCENDIO DE RESIDUOS / BASURA',
    idCNI: 20312,
  },
  {
    idEmergencyType: 204,
    id: '204010001',
    name: 'ACCIDENTE O HUNDIMIENTO DE EMBARCACIÓN SIN PERSONAS LESIONADAS',
    idCNI: 20401,
  },
  {
    idEmergencyType: 204,
    id: '204020001',
    name: 'PERSONA ATRAPADA O PRENSADA',
    idCNI: 20402,
  },
  {
    idEmergencyType: 204,
    id: '204030001',
    name: 'RESCATE ANIMAL',
    idCNI: 20403,
  },
  {
    idEmergencyType: 204,
    id: '204040001',
    name: 'OTROS RESCATES',
    idCNI: 20404,
  },
  {
    idEmergencyType: 204,
    id: '204050001',
    name: 'RESCATE DE FLORA O FAUNA SUJETAS A PROTECCIÓN (TRÁFICO DE ESPECIES)',
    idCNI: 20405,
  },
  {
    idEmergencyType: 204,
    id: '204060001',
    name: 'CONTINGENCIA HUMANO-COCODRILIANOS',
    idCNI: 20406,
  },
  {
    idEmergencyType: 204,
    id: '204070001',
    name: 'PROTECCIÓN DE TORTUGAS',
    idCNI: 20407,
  },
  {
    idEmergencyType: 204,
    id: '204080001',
    name: 'CAÍDA EN LUGAR PROFUNDO',
    idCNI: 20408,
  },
  {
    idEmergencyType: 204,
    id: '204090001',
    name: 'RESCATE POR FENÓMENO NATURAL',
    idCNI: 20409,
  },
  {
    idEmergencyType: 204,
    id: '204100001',
    name: 'RESCATE DE PERSONA NO LOCALIZADA EN ZONA DE DIFÍCIL ACCESO',
    idCNI: 20410,
  },
  {
    idEmergencyType: 301,
    id: '301010001',
    name: 'VEHÍCULO ABANDONADO',
    idCNI: 30101,
  },
  {
    idEmergencyType: 301,
    id: '301020001',
    name: 'OBJETO SOSPECHOSO O PELIGROSO',
    idCNI: 30102,
  },
  {
    idEmergencyType: 301,
    id: '301030001',
    name: 'PERSONA TIRADA EN VÍA PÚBLICA',
    idCNI: 30103,
  },
  {
    idEmergencyType: 302,
    id: '302010001',
    name: 'DETONACIÓN DE EXPLOSIVOS',
    idCNI: 30201,
  },
  {
    idEmergencyType: 302,
    id: '302020001',
    name: 'DETONACIÓN POR ARMA DE FUEGO',
    idCNI: 30202,
  },
  {
    idEmergencyType: 302,
    id: '302030001',
    name: 'PERSONA ARMADA EN ESCUELA',
    idCNI: 30203,
  },
  {
    idEmergencyType: 302,
    id: '302040001',
    name: 'PORTACIÓN DE ARMAS DE FUEGO O CARTUCHOS',
    idCNI: 30204,
  },
  {
    idEmergencyType: 302,
    id: '302050001',
    name: 'DETONACIÓN DE COHETES O FUEGOS ARTIFICIALES',
    idCNI: 30205,
  },
  {
    idEmergencyType: 302,
    id: '302060001',
    name: 'DETONACIÓN DE ARMA DE FUEGO EN ESCUELA',
    idCNI: 30206,
  },
  {
    idEmergencyType: 302,
    id: '302070001',
    name: 'TRÁFICO DE ARMAS O EXPLOSIVOS',
    idCNI: 30207,
  },
  {
    idEmergencyType: 302,
    id: '302080001',
    name: 'ACCIDENTE CON COHETES O FUEGOS ARTIFICIALES',
    idCNI: 30208,
  },
  {
    idEmergencyType: 302,
    id: '302090001',
    name: 'PORTACIÓN DE ARMA BLANCA',
    idCNI: 30209,
  },
  {
    idEmergencyType: 303,
    id: '303010001',
    name: 'AERONAVE SOSPECHOSA',
    idCNI: 30301,
  },
  {
    idEmergencyType: 303,
    id: '303020001',
    name: 'ARRANCONES O CARRERAS DE VEHÍCULOS',
    idCNI: 30302,
  },
  {
    idEmergencyType: 303,
    id: '303030001',
    name: 'BLOQUEO O CORTE DE VÍAS DE COMUNICACIÓN',
    idCNI: 30303,
  },
  {
    idEmergencyType: 303,
    id: '303040001',
    name: 'CIRCULAR EN SENTIDO CONTRARIO',
    idCNI: 30304,
  },
  {
    idEmergencyType: 303,
    id: '303050001',
    name: 'VEHÍCULO A EXCESO DE VELOCIDAD',
    idCNI: 30305,
  },
  {
    idEmergencyType: 303,
    id: '303060001',
    name: 'VEHÍCULO EN HUIDA',
    idCNI: 30306,
  },
  {
    idEmergencyType: 303,
    id: '303070001',
    name: 'VEHÍCULO SOSPECHOSO',
    idCNI: 30307,
  },
  {
    idEmergencyType: 303,
    id: '303080001',
    name: 'VEHÍCULO DESCOMPUESTO',
    idCNI: 30308,
  },
  {
    idEmergencyType: 303,
    id: '303090001',
    name: 'ACCIDENTE DE TRÁNSITO SIN PERSONAS LESIONADAS',
    idCNI: 30309,
  },
  {
    idEmergencyType: 303,
    id: '303100001',
    name: 'OTRAS FALTAS AL REGLAMENTO DE TRÁNSITO',
    idCNI: 30310,
  },
  {
    idEmergencyType: 303,
    id: '303110001',
    name: 'JUGADORES EN VÍA PÚBLICA',
    idCNI: 30311,
  },
  {
    idEmergencyType: 303,
    id: '303120001',
    name: 'ACCIDENTE DE MOTOCICLETA SIN PERSONAS LESIONADAS',
    idCNI: 30312,
  },
  {
    idEmergencyType: 303,
    id: '303130001',
    name: 'ACCIDENTE DE AERONAVE SIN PERSONAS LESIONADAS',
    idCNI: 30313,
  },
  {
    idEmergencyType: 303,
    id: '303140001',
    name: 'ACCIDENTE DE AUTOTRANSPORTE DE CARGA SIN PERSONAS LESIONADAS',
    idCNI: 30314,
  },
  {
    idEmergencyType: 303,
    id: '303150001',
    name: 'ACCIDENTE DE AUTOTRANSPORTE DE PASAJEROS SIN PERSONAS LESIONADAS',
    idCNI: 30315,
  },
  {
    idEmergencyType: 303,
    id: '303160001',
    name: 'ROBO U OBSTRUCCIÓN CASETA DE PEAJE',
    idCNI: 30316,
  },
  {
    idEmergencyType: 304,
    id: '304010001',
    name: 'OTRAS ALARMAS DE EMERGENCIAS ACTIVADAS',
    idCNI: 30401,
  },
  {
    idEmergencyType: 304,
    id: '304020001',
    name: 'BOTÓN DE ALERTA / ALARMA DE EMERGENCIA ACTIVADO',
    idCNI: 30402,
  },
  {
    idEmergencyType: 304,
    id: '304030001',
    name: 'CRISTALAZO O ROBO AL INTERIOR DE VEHÍCULO',
    idCNI: 30403,
  },
  {
    idEmergencyType: 304,
    id: '304040001',
    name: 'DAÑOS A PROPIEDAD AJENA',
    idCNI: 30404,
  },
  {
    idEmergencyType: 304,
    id: '304050001',
    name: 'DESPOJO',
    idCNI: 30405,
  },
  {
    idEmergencyType: 304,
    id: '304060001',
    name: 'EXTORSIÓN O INTENTO DE EXTORSIÓN (NO TELEFÓNICA)',
    idCNI: 30406,
  },
  {
    idEmergencyType: 304,
    id: '304070001',
    name: 'EXTORSIÓN TELEFÓNICA',
    idCNI: 30407,
  },
  {
    idEmergencyType: 304,
    id: '304080001',
    name: 'ACTIVACIÓN DE ALARMA EN ESCUELA',
    idCNI: 30408,
  },
  {
    idEmergencyType: 304,
    id: '304090001',
    name: 'ROBO DE COMBUSTIBLE',
    idCNI: 30409,
  },
  {
    idEmergencyType: 304,
    id: '304100001',
    name: 'ROBO A CAJERO AUTOMÁTICO',
    idCNI: 30410,
  },
  {
    idEmergencyType: 304,
    id: '304110001',
    name: 'ROBO DE AUTOPARTES O ACCESORIOS',
    idCNI: 30411,
  },
  {
    idEmergencyType: 304,
    id: '304120001',
    name: 'ROBO DE GANADO O PRODUCTOS AGROPECUARIOS',
    idCNI: 30412,
  },
  {
    idEmergencyType: 304,
    id: '304130001',
    name: 'ROBO A CASA HABITACIÓN CON VIOLENCIA',
    idCNI: 30413,
  },
  {
    idEmergencyType: 304,
    id: '304140001',
    name: 'ROBO A CASA HABITACIÓN SIN VIOLENCIA',
    idCNI: 30414,
  },
  {
    idEmergencyType: 304,
    id: '304150001',
    name: 'ROBO A ESCUELA CON VIOLENCIA',
    idCNI: 30415,
  },
  {
    idEmergencyType: 304,
    id: '304160001',
    name: 'ROBO A ESCUELA SIN VIOLENCIA',
    idCNI: 30416,
  },
  {
    idEmergencyType: 304,
    id: '304170001',
    name: 'ROBO A GASOLINERA CON VIOLENCIA',
    idCNI: 30417,
  },
  {
    idEmergencyType: 304,
    id: '304180001',
    name: 'ROBO A GASOLINERA SIN VIOLENCIA',
    idCNI: 30418,
  },
  {
    idEmergencyType: 304,
    id: '304190001',
    name: 'ROBO A NEGOCIO CON VIOLENCIA',
    idCNI: 30419,
  },
  {
    idEmergencyType: 304,
    id: '304200001',
    name: 'ROBO A NEGOCIO SIN VIOLENCIA',
    idCNI: 30420,
  },
  {
    idEmergencyType: 304,
    id: '304210001',
    name: 'ROBO A TRANSEÚNTE CON VIOLENCIA',
    idCNI: 30421,
  },
  {
    idEmergencyType: 304,
    id: '304220001',
    name: 'ROBO A TRANSEÚNTE SIN VIOLENCIA',
    idCNI: 30422,
  },
  {
    idEmergencyType: 304,
    id: '304230001',
    name: 'ROBO EN TRANSPORTE COLECTIVO PÚBLICO O PRIVADO CON VIOLENCIA',
    idCNI: 30423,
  },
  {
    idEmergencyType: 304,
    id: '304240001',
    name: 'ROBO EN TRANSPORTE COLECTIVO PÚBLICO O PRIVADO SIN VIOLENCIA',
    idCNI: 30424,
  },
  {
    idEmergencyType: 304,
    id: '304250001',
    name: 'ROBO EN TRANSPORTE PÚBLICO INDIVIDUAL CON VIOLENCIA',
    idCNI: 30425,
  },
  {
    idEmergencyType: 304,
    id: '304260001',
    name: 'ROBO EN TRANSPORTE PÚBLICO INDIVIDUAL SIN VIOLENCIA',
    idCNI: 30426,
  },
  {
    idEmergencyType: 304,
    id: '304270001',
    name: 'ROBO A TRANSPORTISTA O VEHÍCULO COMERCIAL CON VIOLENCIA',
    idCNI: 30427,
  },
  {
    idEmergencyType: 304,
    id: '304280001',
    name: 'ROBO A TRANSPORTISTA O VEHÍCULO COMERCIAL SIN VIOLENCIA',
    idCNI: 30428,
  },
  {
    idEmergencyType: 304,
    id: '304290001',
    name: 'ROBO DE VEHÍCULO PARTICULAR CON VIOLENCIA',
    idCNI: 30429,
  },
  {
    idEmergencyType: 304,
    id: '304300001',
    name: 'ROBO DE VEHÍCULO PARTICULAR SIN VIOLENCIA',
    idCNI: 30430,
  },
  {
    idEmergencyType: 304,
    id: '304310001',
    name: 'ROBO EN CARRETERA CON VIOLENCIA',
    idCNI: 30431,
  },
  {
    idEmergencyType: 304,
    id: '304320001',
    name: 'ROBO EN CARRETERA SIN VIOLENCIA',
    idCNI: 30432,
  },
  {
    idEmergencyType: 304,
    id: '304330001',
    name: 'ROBO A BANCO CON VIOLENCIA',
    idCNI: 30433,
  },
  {
    idEmergencyType: 304,
    id: '304340001',
    name: 'ROBO A BANCO SIN VIOLENCIA',
    idCNI: 30434,
  },
  {
    idEmergencyType: 304,
    id: '304350001',
    name: 'ROBO A CASA DE CAMBIO CON VIOLENCIA',
    idCNI: 30435,
  },
  {
    idEmergencyType: 304,
    id: '304360001',
    name: 'ROBO A CASA DE CAMBIO SIN VIOLENCIA',
    idCNI: 30436,
  },
  {
    idEmergencyType: 304,
    id: '304370001',
    name: 'ROBO A EMPRESA DE TRASLADO DE VALORES CON VIOLENCIA',
    idCNI: 30437,
  },
  {
    idEmergencyType: 304,
    id: '304380001',
    name: 'ROBO A EMPRESA DE TRASLADO DE VALORES SIN VIOLENCIA',
    idCNI: 30438,
  },
  {
    idEmergencyType: 304,
    id: '304390001',
    name: 'ROBO A FERROCARRIL',
    idCNI: 30439,
  },
  {
    idEmergencyType: 304,
    id: '304400001',
    name: 'ROBO DE PLACA',
    idCNI: 30440,
  },
  {
    idEmergencyType: 304,
    id: '304410001',
    name: 'ROBO A TRANSPORTE ESCOLAR CON VIOLENCIA',
    idCNI: 30441,
  },
  {
    idEmergencyType: 304,
    id: '304420001',
    name: 'ROBO A TRANSPORTE ESCOLAR SIN VIOLENCIA',
    idCNI: 30442,
  },
  {
    idEmergencyType: 304,
    id: '304430001',
    name: 'ROBO A EMBARCACIONES Y PIRATERÍA',
    idCNI: 30443,
  },
  {
    idEmergencyType: 304,
    id: '304440001',
    name: 'TRANSPORTE ILEGAL DE COMBUSTIBLE',
    idCNI: 30444,
  },
  {
    idEmergencyType: 304,
    id: '304450001',
    name: 'ROBO A TEMPLO RELIGIOSO SIN VIOLENCIA',
    idCNI: 30445,
  },
  {
    idEmergencyType: 304,
    id: '304460001',
    name: 'OTROS ACTOS RELACIONADOS CON EL PATRIMONIO',
    idCNI: 30446,
  },
  {
    idEmergencyType: 304,
    id: '304470001',
    name: 'ROBO A TEMPLO RELIGIOSO CON VIOLENCIA',
    idCNI: 30447,
  },
  {
    idEmergencyType: 304,
    id: '304480001',
    name: 'SECUESTRO VIRTUAL',
    idCNI: 30448,
  },
  {
    idEmergencyType: 304,
    id: '304490001',
    name: 'TOMA CLANDESTINA DE DUCTOS (HUACHICOLEO)',
    idCNI: 30449,
  },
  {
    idEmergencyType: 304,
    id: '304500001',
    name: 'DINERO FALSO',
    idCNI: 30450,
  },
  {
    idEmergencyType: 304,
    id: '304510001',
    name: 'PERSONA NEGÁNDOSE A PAGAR',
    idCNI: 30451,
  },
  {
    idEmergencyType: 304,
    id: '304520001',
    name: 'ROBO CON VIOLENCIA A CONDUCTOR DE VEHÍCULO PARTICULAR',
    idCNI: 30452,
  },
  {
    idEmergencyType: 304,
    id: '304530001',
    name: 'ROBO SIN VIOLENCIA A CONDUCTOR DE VEHÍCULO PARTICULAR',
    idCNI: 30453,
  },
  {
    idEmergencyType: 304,
    id: '304540001',
    name: 'ROBO DE MATERIAL PELIGROSO O RADIOACTIVO',
    idCNI: 30454,
  },
  {
    idEmergencyType: 304,
    id: '304550001',
    name: 'BLOQUEO DE ENTRADA / SALIDA DE DOMICILIO',
    idCNI: 30455,
  },
  {
    idEmergencyType: 304,
    id: '304560001',
    name: 'ALARMA DE BANCO',
    idCNI: 30456,
  },
  {
    idEmergencyType: 304,
    id: '304570001',
    name: 'DAÑOS A DISPOSITIVOS DE MONITOREO Y ALERTAMIENTO DE SEGURIDAD PÚBLICA',
    idCNI: 30457,
  },
  {
    idEmergencyType: 304,
    id: '304580001',
    name: 'ROBO A CUENTAHABIENTE Y/O TARJETAHABIENTE',
    idCNI: 30458,
  },
  {
    idEmergencyType: 304,
    id: '304590001',
    name: 'ROBO DE ANIMALES',
    idCNI: 30459,
  },
  {
    idEmergencyType: 304,
    id: '304600001',
    name: 'ROBO A REPARTIDOR CON VIOLENCIA',
    idCNI: 30460,
  },
  {
    idEmergencyType: 304,
    id: '304610001',
    name: 'ROBO A REPARTIDOR SIN VIOLENCIA',
    idCNI: 30461,
  },
  {
    idEmergencyType: 304,
    id: '304620001',
    name: 'ROBO DE MOTOCICLETA CON VIOLENCIA',
    idCNI: 30462,
  },
  {
    idEmergencyType: 304,
    id: '304630001',
    name: 'ROBO DE MOTOCICLETA SIN VIOLENCIA',
    idCNI: 30463,
  },
  {
    idEmergencyType: 304,
    id: '304640001',
    name: 'ROBO O EXTRACCIÓN ILÍCITA DE MATERIAL MINERAL',
    idCNI: 30464,
  },
  {
    idEmergencyType: 304,
    id: '304650001',
    name: 'DETECCIÓN DE PLACA SOBREPUESTA',
    idCNI: 30465,
  },
  {
    idEmergencyType: 304,
    id: '304660001',
    name: 'DETECCIÓN DE VEHÍCULO CON REPORTE DE ROBO',
    idCNI: 30466,
  },
  {
    idEmergencyType: 305,
    id: '305010001',
    name: 'ABANDONO DE PERSONA',
    idCNI: 30501,
  },
  {
    idEmergencyType: 305,
    id: '305020001',
    name: 'VIOLENCIA DE PAREJA',
    idCNI: 30502,
  },
  {
    idEmergencyType: 305,
    id: '305030001',
    name: 'VIOLENCIA FAMILIAR',
    idCNI: 30503,
  },
  {
    idEmergencyType: 305,
    id: '305040001',
    name: 'OTROS ACTOS RELACIONADOS CON LA FAMILIA',
    idCNI: 30504,
  },
  {
    idEmergencyType: 305,
    id: '305050001',
    name: 'MALTRATO DE NIÑAS, NIÑOS Y ADOLESCENTES',
    idCNI: 30505,
  },
  {
    idEmergencyType: 305,
    id: '305060001',
    name: 'MALTRATO DE PERSONAS ADULTAS MAYORES',
    idCNI: 30506,
  },
  {
    idEmergencyType: 306,
    id: '306010001',
    name: 'NIÑA, NIÑO O ADOLESCENTE EXTRAVIADO',
    idCNI: 30601,
  },
  {
    idEmergencyType: 306,
    id: '306020001',
    name: 'PERSONA NO LOCALIZADA O DESAPARECIDA',
    idCNI: 30602,
  },
  {
    idEmergencyType: 306,
    id: '306030001',
    name: 'PRIVACIÓN DE LA LIBERTAD',
    idCNI: 30603,
  },
  {
    idEmergencyType: 306,
    id: '306040001',
    name: 'REHENES',
    idCNI: 30604,
  },
  {
    idEmergencyType: 306,
    id: '306050001',
    name: 'ROBO DE NIÑAS, NIÑOS O ADOLESCENTES',
    idCNI: 30605,
  },
  {
    idEmergencyType: 306,
    id: '306060001',
    name: 'PERSONA DETENIDA',
    idCNI: 30606,
  },
  {
    idEmergencyType: 306,
    id: '306070001',
    name: 'SUSTRACCIÓN, RETENCIÓN U OCULTAMIENTO DE NIÑAS, NIÑOS, ADOLESCENTES O PERSONAS INCAPACES',
    idCNI: 30607,
  },
  {
    idEmergencyType: 306,
    id: '306080001',
    name: 'TRÁFICO DE NIÑAS, NIÑOS Y ADOLESCENTES',
    idCNI: 30608,
  },
  {
    idEmergencyType: 306,
    id: '306090001',
    name: 'OTROS ACTOS RELACIONADOS CON LA LIBERTAD PERSONAL',
    idCNI: 30609,
  },
  {
    idEmergencyType: 306,
    id: '306100001',
    name: 'TENTATIVA DE PRIVACIÓN DE LA LIBERTAD',
    idCNI: 30610,
  },
  {
    idEmergencyType: 306,
    id: '306110001',
    name: 'NOTIFICACIÓN DE CIBER INCIDENTE',
    idCNI: 30611,
  },
  {
    idEmergencyType: 306,
    id: '306120001',
    name: 'NOTICIAS FALSAS',
    idCNI: 30612,
  },
  {
    idEmergencyType: 306,
    id: '306130001',
    name: 'FRAUDE ELECTRÓNICO',
    idCNI: 30613,
  },
  {
    idEmergencyType: 306,
    id: '306140001',
    name: 'SEXTORSIÓN',
    idCNI: 30614,
  },
  {
    idEmergencyType: 306,
    id: '306150001',
    name: 'ACOSO CIBERNÉTICO',
    idCNI: 30615,
  },
  {
    idEmergencyType: 306,
    id: '306160001',
    name: 'PERSONA DESORIENTADA',
    idCNI: 30616,
  },
  {
    idEmergencyType: 307,
    id: '307010001',
    name: 'ABUSO SEXUAL',
    idCNI: 30701,
  },
  {
    idEmergencyType: 307,
    id: '307020001',
    name: 'ACOSO U HOSTIGAMIENTO SEXUAL',
    idCNI: 30702,
  },
  {
    idEmergencyType: 307,
    id: '307030001',
    name: 'AFECTACIÓN A LA INTIMIDAD SEXUAL',
    idCNI: 30703,
  },
  {
    idEmergencyType: 307,
    id: '307050001',
    name: 'EXPLOTACIÓN DE NIÑAS, NIÑOS Y ADOLESCENTES',
    idCNI: 30705,
  },
  {
    idEmergencyType: 307,
    id: '307060001',
    name: 'TRATA DE NIÑAS, NIÑOS Y ADOLESCENTES',
    idCNI: 30706,
  },
  {
    idEmergencyType: 307,
    id: '307070001',
    name: 'VIOLACIÓN',
    idCNI: 30707,
  },
  {
    idEmergencyType: 307,
    id: '307080001',
    name: 'OTROS ACTOS RELACIONADOS CON LA LIBERTAD Y LA SEGURIDAD SEXUAL',
    idCNI: 30708,
  },
  {
    idEmergencyType: 307,
    id: '307090001',
    name: 'TRATA DE PERSONAS',
    idCNI: 30709,
  },
  {
    idEmergencyType: 307,
    id: '307100001',
    name: 'TRÁFICO DE PERSONAS / INDOCUMENTADAS',
    idCNI: 30710,
  },
  {
    idEmergencyType: 307,
    id: '307110001',
    name: 'CORRUPCIÓN DE PERSONAS MENORES DE EDAD O INCAPACES',
    idCNI: 30711,
  },
  {
    idEmergencyType: 307,
    id: '307120001',
    name: 'VIOLENCIA DIGITAL O MEDIÁTICA',
    idCNI: 30712,
  },
  {
    idEmergencyType: 307,
    id: '307130001',
    name: 'PORNOGRAFÍA INFANTIL',
    idCNI: 30713,
  },
  {
    idEmergencyType: 307,
    id: '307140001',
    name: 'EXPLOTACIÓN DE PERSONAS INCAPACITADAS O DISCAPACITADAS',
    idCNI: 30714,
  },
  {
    idEmergencyType: 308,
    id: '308010001',
    name: 'ACTOS DE COMERCIALIZACIÓN ILEGAL DE SANGRE, ÓRGANOS Y TEJIDOS HUMANOS',
    idCNI: 30801,
  },
  {
    idEmergencyType: 308,
    id: '308020001',
    name: 'ASOCIACIÓN DELICTUOSA O PANDILLERISMO',
    idCNI: 30802,
  },
  {
    idEmergencyType: 308,
    id: '308030001',
    name: 'ENFRENTAMIENTO DE GRUPOS ARMADOS',
    idCNI: 30803,
  },
  {
    idEmergencyType: 308,
    id: '308040001',
    name: 'TERRORISMO O ATENTADO',
    idCNI: 30804,
  },
  {
    idEmergencyType: 308,
    id: '308050001',
    name: 'AMENAZA DE BOMBA',
    idCNI: 30805,
  },
  {
    idEmergencyType: 308,
    id: '308060001',
    name: 'OTROS ACTOS RELACIONADOS CON LA SEGURIDAD COLECTIVA',
    idCNI: 30806,
  },
  {
    idEmergencyType: 308,
    id: '308070001',
    name: 'MOTÍN',
    idCNI: 30807,
  },
  {
    idEmergencyType: 308,
    id: '308080001',
    name: 'VENTA CLANDESTINA DE PIROTECNIA, COHETES O FUEGOS ARTIFICIALES',
    idCNI: 30808,
  },
  {
    idEmergencyType: 308,
    id: '308090001',
    name: 'VENTA ILEGAL DE COMBUSTIBLE',
    idCNI: 30809,
  },
  {
    idEmergencyType: 308,
    id: '308100001',
    name: 'AMENAZA DE BOMBA EN ESCUELA',
    idCNI: 30810,
  },
  {
    idEmergencyType: 308,
    id: '308110001',
    name: 'AMENAZA A CIVILES, LOCALIDADES, CORPORACIONES O PERSONAS SERVIDORAS PÚBLICAS (PINTA O NARCOMANTA)',
    idCNI: 30811,
  },
  {
    idEmergencyType: 309,
    id: '309010001',
    name: 'OTROS ACTOS RELACIONADOS CON LA VIDA Y LA INTEGRIDAD PERSONAL',
    idCNI: 30901,
  },
  {
    idEmergencyType: 309,
    id: '309030001',
    name: 'VIOLENCIA CONTRA LAS MUJERES',
    idCNI: 30903,
  },
  {
    idEmergencyType: 309,
    id: '309040001',
    name: 'PERSONA SOSPECHOSA',
    idCNI: 30904,
  },
  {
    idEmergencyType: 309,
    id: '309050001',
    name: 'AMENAZA DE SUICIDIO',
    idCNI: 30905,
  },
  {
    idEmergencyType: 309,
    id: '309060001',
    name: 'HOMICIDIO',
    idCNI: 30906,
  },
  {
    idEmergencyType: 309,
    id: '309070001',
    name: 'PERSONA AGRESIVA',
    idCNI: 30907,
  },
  {
    idEmergencyType: 309,
    id: '309080001',
    name: 'SUICIDIO',
    idCNI: 30908,
  },
  {
    idEmergencyType: 309,
    id: '309090001',
    name: 'AGRESIÓN FÍSICA',
    idCNI: 30909,
  },
  {
    idEmergencyType: 309,
    id: '309100001',
    name: 'ACOSO ESCOLAR (BULLYING)',
    idCNI: 30910,
  },
  {
    idEmergencyType: 309,
    id: '309110001',
    name: 'ACTOS RELACIONADOS CON MIGRANTES',
    idCNI: 30911,
  },
  {
    idEmergencyType: 309,
    id: '309120001',
    name: 'RESCATE DE MIGRANTES (EXTRANJEROS INDOCUMENTADOS)',
    idCNI: 30912,
  },
  {
    idEmergencyType: 310,
    id: '310010001',
    name: 'ALLANAMIENTO DE MORADA',
    idCNI: 31001,
  },
  {
    idEmergencyType: 310,
    id: '310020001',
    name: 'AMENAZA',
    idCNI: 31002,
  },
  {
    idEmergencyType: 310,
    id: '310030001',
    name: 'ROBO O DAÑO A BIENES PÚBLICOS, INSTITUCIONES, MONUMENTOS, ENTRE OTROS',
    idCNI: 31003,
  },
  {
    idEmergencyType: 310,
    id: '310040001',
    name: 'DESCARGA DE DESECHOS SIN PERMISOS',
    idCNI: 31004,
  },
  {
    idEmergencyType: 310,
    id: '310050001',
    name: 'INCIDENTES ELECTORALES',
    idCNI: 31005,
  },
  {
    idEmergencyType: 310,
    id: '310060001',
    name: 'FUGA DE REOS',
    idCNI: 31006,
  },
  {
    idEmergencyType: 310,
    id: '310070001',
    name: 'NARCOMENUDEO',
    idCNI: 31007,
  },
  {
    idEmergencyType: 310,
    id: '310080001',
    name: 'TOMA DE EDIFICIO PÚBLICO',
    idCNI: 31008,
  },
  {
    idEmergencyType: 310,
    id: '310090001',
    name: 'TOMA DE INSTALACIONES EDUCATIVAS CON VIOLENCIA',
    idCNI: 31009,
  },
  {
    idEmergencyType: 310,
    id: '310100001',
    name: 'TALA ILEGAL',
    idCNI: 31010,
  },
  {
    idEmergencyType: 310,
    id: '310110001',
    name: 'TRÁFICO DE MADERA',
    idCNI: 31011,
  },
  {
    idEmergencyType: 310,
    id: '310120001',
    name: 'TRÁFICO Y/O VENTA ILEGAL DE ANIMALES',
    idCNI: 31012,
  },
  {
    idEmergencyType: 310,
    id: '310130001',
    name: 'TRÁFICO DE DROGAS Y ESTUPEFACIENTES EN LA MAR',
    idCNI: 31013,
  },
  {
    idEmergencyType: 310,
    id: '310140001',
    name: 'TRÁFICO DE DROGAS Y ESTUPEFACIENTES EN VÍA PÚBLICA',
    idCNI: 31014,
  },
  {
    idEmergencyType: 310,
    id: '310150001',
    name: 'OTROS ACTOS RELACIONADOS CON OTROS BIENES JURÍDICOS',
    idCNI: 31015,
  },
  {
    idEmergencyType: 310,
    id: '310160001',
    name: 'CAZA ILEGAL',
    idCNI: 31016,
  },
  {
    idEmergencyType: 310,
    id: '310170001',
    name: 'INCIDENTE EN DUCTOS DE HIDROCARBUROS',
    idCNI: 31017,
  },
  {
    idEmergencyType: 311,
    id: '311010001',
    name: 'ALTERACIÓN DEL ORDEN PÚBLICO POR PERSONA ALCOHOLIZADA',
    idCNI: 31101,
  },
  {
    idEmergencyType: 311,
    id: '311020001',
    name: 'ALTERACIÓN DEL ORDEN PÚBLICO POR PERSONA DROGADA',
    idCNI: 31102,
  },
  {
    idEmergencyType: 311,
    id: '311030001',
    name: 'CONDUCTOR EBRIO / DROGADO',
    idCNI: 31103,
  },
  {
    idEmergencyType: 311,
    id: '311040001',
    name: 'CONSUMO DE ALCOHOL EN VÍA PÚBLICA',
    idCNI: 31104,
  },
  {
    idEmergencyType: 311,
    id: '311050001',
    name: 'CONSUMO DE DROGAS EN VÍA PÚBLICA',
    idCNI: 31105,
  },
  {
    idEmergencyType: 311,
    id: '311060001',
    name: 'CONSUMO DE ALCOHOL EN ESCUELA',
    idCNI: 31106,
  },
  {
    idEmergencyType: 311,
    id: '311070001',
    name: 'CONSUMO DE DROGAS EN ESCUELA',
    idCNI: 31107,
  },
  {
    idEmergencyType: 311,
    id: '311080001',
    name: 'GRAFITIS',
    idCNI: 31108,
  },
  {
    idEmergencyType: 311,
    id: '311090001',
    name: 'MANIFESTACIÓN CON DISTURBIOS O BLOQUEOS',
    idCNI: 31109,
  },
  {
    idEmergencyType: 311,
    id: '311100001',
    name: 'MITIN',
    idCNI: 31110,
  },
  {
    idEmergencyType: 311,
    id: '311110001',
    name: 'PELEA CLANDESTINA CON ANIMALES',
    idCNI: 31111,
  },
  {
    idEmergencyType: 311,
    id: '311120001',
    name: 'RIÑA',
    idCNI: 31112,
  },
  {
    idEmergencyType: 311,
    id: '311130001',
    name: 'PERSONA EXHIBICIONISTA',
    idCNI: 31113,
  },
  {
    idEmergencyType: 311,
    id: '311140001',
    name: 'OTROS TIPOS DE ALTERACIÓN AL ORDEN PÚBLICO',
    idCNI: 31114,
  },
  {
    idEmergencyType: 311,
    id: '311150001',
    name: 'VENTA ILEGAL DE BEBIDAS ALCOHÓLICAS',
    idCNI: 31115,
  },
  {
    idEmergencyType: 312,
    id: '312010001',
    name: 'HALLAZGO DE CADÁVER / RESTOS HUMANOS',
    idCNI: 31201,
  },
  {
    idEmergencyType: 312,
    id: '312020001',
    name: 'HALLAZGO DE ARMA',
    idCNI: 31202,
  },
  {
    idEmergencyType: 312,
    id: '312030001',
    name: 'HALLAZGO DE VEHÍCULO ROBADO',
    idCNI: 31203,
  },
  {
    idEmergencyType: 401,
    id: '401010001',
    name: 'BARDA CAÍDA O POR CAER',
    idCNI: 40101,
  },
  {
    idEmergencyType: 401,
    id: '401020001',
    name: 'POSTE CAÍDO O POR CAER',
    idCNI: 40102,
  },
  {
    idEmergencyType: 401,
    id: '401030001',
    name: 'FALLA DE ALUMBRADO PÚBLICO',
    idCNI: 40103,
  },
  {
    idEmergencyType: 401,
    id: '401040001',
    name: 'FALLAS DE SEMÁFORO',
    idCNI: 40104,
  },
  {
    idEmergencyType: 401,
    id: '401050001',
    name: 'ALCANTARILLA SIN TAPA',
    idCNI: 40105,
  },
  {
    idEmergencyType: 401,
    id: '401060001',
    name: 'CABLES COLGANDO O CAÍDOS',
    idCNI: 40106,
  },
  {
    idEmergencyType: 401,
    id: '401070001',
    name: 'CORTO CIRCUITO',
    idCNI: 40107,
  },
  {
    idEmergencyType: 401,
    id: '401080001',
    name: 'GRAVA SUELTA O MATERIAL ESPARCIDO',
    idCNI: 40108,
  },
  {
    idEmergencyType: 401,
    id: '401090001',
    name: 'AFECTACIÓN DE LOS SERVICIOS BÁSICOS O DE INFRAESTRUCTURA ESTRATÉGICA',
    idCNI: 40109,
  },
  {
    idEmergencyType: 401,
    id: '401100001',
    name: 'VIALIDAD EN MAL ESTADO',
    idCNI: 40110,
  },
  {
    idEmergencyType: 401,
    id: '401110001',
    name: 'FUGA DE AGUA',
    idCNI: 40111,
  },
  {
    idEmergencyType: 401,
    id: '401120001',
    name: 'CAÍDA DE TECHO, CASA, EDIFICIO O CONSTRUCCIÓN',
    idCNI: 40112,
  },
  {
    idEmergencyType: 401,
    id: '401130001',
    name: 'DAÑO ESTRUCTURAL Y/O AGRIETAMIENTOS DE EDIFICACIONES',
    idCNI: 40113,
  },
  {
    idEmergencyType: 401,
    id: '401140001',
    name: 'ALCANTARILLA OBSTRUIDA',
    idCNI: 40114,
  },
  {
    idEmergencyType: 401,
    id: '401150001',
    name: 'ANIMAL MUERTO EN VÍA PÚBLICA',
    idCNI: 40115,
  },
  {
    idEmergencyType: 501,
    id: '501010001',
    name: 'CONCENTRACIÓN PACÍFICA DE PERSONAS',
    idCNI: 50101,
  },
  {
    idEmergencyType: 501,
    id: '501020001',
    name: 'TENTATIVA DE ROBO',
    idCNI: 50102,
  },
  {
    idEmergencyType: 501,
    id: '501030001',
    name: 'EXTRAVÍO DE PLACA',
    idCNI: 50103,
  },
  {
    idEmergencyType: 501,
    id: '501040001',
    name: 'FRAUDE',
    idCNI: 50104,
  },
  {
    idEmergencyType: 501,
    id: '501050001',
    name: 'RUIDO EXCESIVO',
    idCNI: 50105,
  },
  {
    idEmergencyType: 501,
    id: '501060001',
    name: 'USURPACIÓN DE IDENTIDAD / FUNCIONES',
    idCNI: 50106,
  },
  {
    idEmergencyType: 501,
    id: '501070001',
    name: 'ABUSO DE CONFIANZA',
    idCNI: 50107,
  },
  {
    idEmergencyType: 501,
    id: '501080001',
    name: 'ABUSO DE AUTORIDAD',
    idCNI: 50108,
  },
  {
    idEmergencyType: 501,
    id: '501090001',
    name: 'INGRESO A HOSPITAL',
    idCNI: 50109,
  },
  {
    idEmergencyType: 501,
    id: '501100001',
    name: 'INTENTO DE FRAUDE',
    idCNI: 50110,
  },
  {
    idEmergencyType: 503,
    id: '503010001',
    name: 'APOYO A LA POBLACIÓN',
    idCNI: 50301,
  },
  {
    idEmergencyType: 503,
    id: '503020001',
    name: 'PERSONA LOCALIZADA',
    idCNI: 50302,
  },
  {
    idEmergencyType: 503,
    id: '503030001',
    name: 'MALTRATO DE ANIMALES',
    idCNI: 50303,
  },
  {
    idEmergencyType: 503,
    id: '503040001',
    name: 'PERSONA EN SITUACIÓN DE CALLE',
    idCNI: 50304,
  },
  {
    idEmergencyType: 503,
    id: '503050001',
    name: 'SOLICITUD DE RONDÍN',
    idCNI: 50305,
  },
  {
    idEmergencyType: 503,
    id: '503060001',
    name: 'QUEJA CONTRA SERVIDORES PÚBLICOS',
    idCNI: 50306,
  },
  {
    idEmergencyType: 601,
    id: '304460002',
    name: 'SISTEMA DE ALERTAMIENTO SILENCIOSO CÓDIGO C5',
    idCNI: 60103,
  },
  {
    idEmergencyType: 601,
    id: '310160001',
    name: 'ALERTAMIENTO POR ARCOS CARRETEROS',
    idCNI: 60103,
  },
  {
    idEmergencyType: 601,
    id: '310170001',
    name: 'ALERTAMIENTO POR BOTÓN DE AUXILIO',
    idCNI: 60103,
  },
  {
    idEmergencyType: 601,
    id: '601030001',
    name: 'SOLICITUD DE OTROS SERVICIOS PÚBLICOS',
    idCNI: 60103,
  },
  {
    idEmergencyType: 602,
    id: '602010001',
    name: 'LLAMADA DE PRUEBA',
    idCNI: 60201,
  },
  {
    idEmergencyType: 602,
    id: '602020001',
    name: 'LLAMADA INCOMPLETA',
    idCNI: 60202,
  },
  {
    idEmergencyType: 602,
    id: '602030001',
    name: 'TRANSFERENCIA DE LLAMADA',
    idCNI: 60203,
  },
  {
    idEmergencyType: 602,
    id: '602040001',
    name: 'SOLICITUD DE INFORMACIÓN',
    idCNI: 60204,
  },
  {
    idEmergencyType: 602,
    id: '602050001',
    name: 'INFORMACIÓN DE INCIDENTE YA REPORTADO (REPETIDO)',
    idCNI: 60205,
  },
  {
    idEmergencyType: 602,
    id: '602060001',
    name: 'LLAMADA DE FELICITACIÓN',
    idCNI: 60206,
  },
  {
    idEmergencyType: 602,
    id: '602070001',
    name: 'LLAMADA DE SEGUIMIENTO',
    idCNI: 60207,
  },
  {
    idEmergencyType: 602,
    id: '602080001',
    name: 'LLAMADA DE QUEJA',
    idCNI: 60208,
  },
  {
    idEmergencyType: 602,
    id: '602090001',
    name: 'APOYO A OTRO ESTADO / CALLE',
    idCNI: 60209,
  },
  {
    idEmergencyType: 602,
    id: '602100001',
    name: 'SIMULACRO',
    idCNI: 60210,
  },
  {
    idEmergencyType: 701,
    id: '701010001',
    name: 'LLAMADA DE BROMA POR PERSONAS MENORES DE EDAD',
    idCNI: 70101,
  },
  {
    idEmergencyType: 701,
    id: '701040001',
    name: 'LLAMADA MUDA',
    idCNI: 70104,
  },
  {
    idEmergencyType: 701,
    id: '701060001',
    name: 'LLAMADAS CON PALABRAS OBSCENAS Y/O INSULTOS POR PERSONAS ADULTAS',
    idCNI: 70106,
  },
  {
    idEmergencyType: 701,
    id: '701070001',
    name: 'LLAMADA DE BROMA POR PERSONAS ADULTAS / JÓVENES',
    idCNI: 70107,
  },
  {
    idEmergencyType: 701,
    id: '701090001',
    name: 'LLAMADAS COLGADAS',
    idCNI: 70109,
  },
];
