<template>
  <div class="hidebutton" v-if="!show">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn style="
            color: rgb(255 255 255 / 70%) !important;
            background-color: rgb(20, 20, 20, 0.7);
            pointer-events: auto;
          " icon x-large v-on="on" @click="toggle">
          <v-icon>mdi-alert</v-icon>
        </v-btn>
      </template>
      <span>ALERTAS</span>
    </v-tooltip>
  </div>
  <map-card title="Alertas" :actions="actions" v-else>
    <template v-slot:body>
      <v-tabs :show-arrows="true" height="30" background-color="secondary" v-model="tab" dark class="tab-alert">
        <v-tab>
          <v-badge color="primary" :content="incidentsCount" inline left> 9-1-1 </v-badge>
        </v-tab>
        <v-tab v-if="getUser.attributes.permission_sinaloaSOS">
          <v-badge color="primary" :content="SOSCallsCount" inline left > SOS </v-badge>
        </v-tab>
        <v-tab>
          <v-badge color="primary" dark :content="devicesCount" inline left> Alertas </v-badge>
        </v-tab>
        <v-tab>
          <v-badge color="primary" :content="hitCount" inline left> Hits </v-badge>
        </v-tab>
        <v-tab v-if="!getUser.readonly && !(getUser.attributes.permission_reportantes === false)">
          <v-badge color="primary" :content="reportantsCount" inline left> Reportantes </v-badge>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="height: calc(100% - 30px)" class="overflow-y-auto" dark>
        <v-tab-item dark>
          <v-list dark class="pa-0">
            <template v-for="item in incidentsItems">
              <!-- eslint-disable vue/valid-v-for -->
              <v-list-item @click="centerAlert(item)" :key="'i' + item.id">
                <!--eslint-enable-->
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ item.name }}
                      </div>
                      <div>
                        <img src="../../../assets/map/sos.png" :alt="item.uniqueId" width="20" height="20"
                          v-if="item.typeOfItem === 'sosIncident'" />
                        <v-img :src="getIncidentSrc(item.categoryData)" width="20" height="20" v-else-if="item.categoryData"></v-img>
                        <img src="../../../assets/map/onstar.png" :alt="item.uniqueId" width="20" height="20"
                          v-if="item.typeOfItem === 'onstarIncident'" />
                        <v-icon v-html="mapIcons[item.category]" size="24" v-else></v-icon>
                      </div>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle> {{ item.tipoIncidente }} </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ getPrioridad(item) }}
                      </div>
                      <div>
                        {{ item.createdAt | fromNow }}
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'div' + item.id"></v-divider>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item dark v-if="getUser.attributes.permission_sinaloaSOS">
          <v-list dark class="pa-0">
            <template v-for="item in sosCallItems">
              <!-- eslint-disable vue/valid-v-for -->
              <v-list-item @click="centerAlert(item)" :key="'i' + item.id">
                <!--eslint-enable-->
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ getUserName(item) }}
                      </div>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ getCanonName(item.type) }}
                      </div>
                      <div>
                        {{ getCanonName(item.status) }}
                      </div>
                    </div>
                   </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ getPhoneNumber(item) }}
                      </div>
                      <div>
                        {{ item.createdAt | fromNow }}
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'div' + item.id"></v-divider>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-list dark class="pa-0">
            <v-list-item v-for="item in devicesItems" :key="item.id" @click="centerAlert(item)">
              <v-list-item-icon>
                <v-icon v-html="mapIcons[item.typeOfItem]"></v-icon>
              </v-list-item-icon>

              <v-list-item-content v-if="item.typeOfItem === 'rapidsosIncident'">
                <v-list-item-title v-text="item.riderName"></v-list-item-title>
                <v-list-item-subtitle> {{ item.createdAt | fromNow }} </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else-if="item.typeOfItem === 'devices'">
                <v-list-item-title>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ item.name }}
                    </div>
                    <div>
                      <v-icon
                        v-if="!item.attributes.razonamiento && (item.type === 'geofenceEnter' || item.type === 'geofenceExit')"
                        style="margin-right: 5px;"
                        size="20" @click="addRazonamientoToEvent(item)">mdi-file-document-edit-outline</v-icon>
                      <v-icon size="22" @click="removeDeviceAlerts(item.id)">mdi-close</v-icon>
                    </div>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle> {{ item.event.descripcion }} </v-list-item-subtitle>
                <v-list-item-subtitle> {{ (item.event._addedAt || item.event.createdAt) | fromNow }} </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle> {{ item._addedAt | fromNow }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-tab-item>
        <v-tab-item dark>
          <v-list dark class="pa-0">
            <template v-for="(item, index) in getHitAlerts">
              <v-list-item @click="centerAlert(item)" :key="'hit' + index">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ getHitTitle(item) }}
                      </div>
                      <div></div>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle> {{ getHitSubtitle(item) }} </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <div class="d-flex justify-space-between">
                      <div>
                        {{ item.hitType === 'hitLpr' ? 'LPR' : item.hitType }}
                      </div>
                      <div>
                        {{ item.createdAt | datetime }}
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'div' + index"></v-divider>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item dark v-if="!getUser.readonly">
          <v-list dark class="pa-0">
            <v-list-item @click="centerAlert(item)" v-for="item in reportantsItems" :key="item._id">
              <v-list-item-icon>
                <img alt="rapidsos" style="height: 25px;" src="rapidsos.png" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ item && item ? item.additionalData.phone : '' }}
                    </div>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle> {{ shortAddress(item) }} </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ convertToDate(item) | datetime }}
                    </div>
                    <div>
                      {{ convertToDate(item) | fromNow }}
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </map-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'underscore';
import Util from '@/util'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service';
import alertTypes from './alertTypes';

export default {
  components: {
    'map-card': () => import('@/components/MapCard.vue'),
  },
  props: {},
  data() {
    return {
      show: true,
      tab: 0,
      selected: [2],
      mapDeviceEventType: Util.mapDeviceEventType,
      mapIcons: {
        sensors: '$sensor-white',
        buses: '$bus-white',
        lprs: '$lpr-white',
        medico: '$incidente-medico',
        proteccion_civil: '$incidente-proteccion_civil',
        seguridad: '$incidente-seguridad',
        servicios_publicos: '$incidente-servicios_publicos',
        rapidsosIncident: '$uber',
        devices: '$device-white',
        CANACAR: '$sensor-white',
        HANDHELD: '$sensor-white',
      },
      actions: [
        {
          icon: 'mdi-triangle-outline mdi-rotate-90',
          help: 'Ocultar',
          action: this.toggle,
        },
      ],
      alertTypes,
    };
  },
  methods: {
    ...mapActions('alerts', [
      'removeLprHitAlerts',
      'removeSensorAlerts',
      'removeExternalSensorAlerts',
      'removeIncidentAlerts',
      'removeBusAlerts',
      'removeDeviceAlerts'
    ]),
    getPhoneNumber(item) {
      return item.userData?.phoneNumber
    },
    getUserName(item) {
      return item.userData?.name
    },
    getCanonName(name) {
      const names = {
        VIDEO_CALL: 'Video llamada',
        CALL: 'Llamada de voz',
        CHAT: 'Chat',
        CREATED: 'Nueva',
        WAITING_OPERATOR: 'Esperando operador',
        IN_PROGRESS: 'En progreso',
        RECONNECTING: 'Reconectando',
        FINISHED: 'Finalizada',
      }
      return names[name] || name
    },
    centerAlert(item) {
      console.log('centerAlert', item);
      this.$parent.$emit('center-alert', item);
    },
    getIncidentSrc(incidentCategory) {
      return (process.env.VUE_APP_IMAGES_PATH || '/api/v2/public/images/') + (incidentCategory.image.split('/').pop())
    },
    getDate(lastHit) {
      return moment(lastHit).format('DD-MM-YYYY HH:mm');
    },
    getPrioridad(item) {
      return item.attributes?.prioridad;
    },
    getHitTitle(item) {
      if (item.typeOfItem === 'hitRoboVehiculo' && item.attributes && item.attributes.Vehiculo) {
        const vehiculo = item.attributes.Vehiculo;
        return vehiculo.PLACA;
      }
      if (item.typeOfItem === 'hitLpr') {
        return `Deteccion LPR ${item.data.placa}`;
      }
      if (item.typeOfItem === 'hitMandamiento' && item.attributes && item.attributes.Mandamientos) {
        const mandamiento = item.attributes.Mandamientos;
        return mandamiento.expediente;
      }
      return '';
    },
    getHitSubtitle(item) {
      if (item.typeOfItem === 'hitRoboVehiculo' && item.attributes && item.attributes.Vehiculo) {
        const vehiculo = item.attributes.Vehiculo;
        return `${vehiculo.MARCA} ${vehiculo.LINEA} ${vehiculo.COLOR}`;
      }
      if (item.typeOfItem === 'hitLpr') {
        return `${item?.analyticDevice?.name}`;
      }
      if (item.typeOfItem === 'hitMandamiento' && item.attributes && item.attributes.Mandamientos) {
        const mandamiento = item.attributes.Mandamientos;
        return `${mandamiento.dnombre} ${mandamiento.dpaterno} ${mandamiento.dmaterno}`;
      }
      return '';
    },
    calcularTiempoSalida(durationMs) {
      if (!durationMs) {
        return 0
      }
      const segundos = (durationMs / 1000).toFixed(0)
      if (parseInt(segundos) <= 59) {
        return segundos + ' ' + (parseInt(segundos) === 1 ? 'segundo' : 'segundos')
      }
      const minutos = (parseInt(segundos) / 60).toFixed(0)
      if (parseInt(minutos) <= 119) {
        return minutos + ' ' + (parseInt(minutos) === 1 ? 'minuto' : 'minutos')
      }
      return parseInt(minutos / 60).toFixed(0) + ' horas'
    },
    toggle() {
      this.show = !this.show;
    },
    convertToDate(item) {
      return new Date(parseInt(item?.lastPosition?.additionalData?.call_start_time || 0));
    },
    shortAddress(item) {
      if (Array.isArray(item?.positions) && item?.positions.length > 0) {
        const address = item.positions?.[0]?.additionalData?.address
        if (address) {
          return ` ${address.colonia}, ${address.poblacion}, ${address.municipio}`
        }
      }
      return `DESCONOCIDO`
    },
    addRazonamientoToEvent(item) {
      const form = new Form({
        schema: {
          razonamiento: {
            label: 'Razonamiento',
            type: String,
            textarea: true,
          },
          status: {
            label: 'Estatus',
            type: String,
            options: [{
              id: 'Positivo',
              name: 'Positivo'
            }, {
              id: 'Falso',
              name: 'Falso'
            }]
          }
        }
      })
      const dialog = new Dialog({
        title: item.name,
        subtitle: 'Razonamiento de ' + (this.mapDeviceEventType[item.type] || item.type),
        actions: [{
          help: 'Guardar razonamiento',
          icon: 'mdi-content-save',
          color: 'secondary',
          action: () => {
            if (form.hasErrors()) {
              return
            }
            ApiService({
              method: 'POST',
              url: '/events/acknowledge/' + item.id,
              data: form.getItem()
            }).then(() => {
              item.attributes = {
                ...item.attributes,
                ...form.getItem()
              }
              dialog.close()
            })
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    }
  },
  computed: {
    SOSCallsCount() {
      return this.getSOSCallAlerts.length || 0 || '0';
    },
    incidentsCount() {
      return this.getIncidentAlerts.length || 0 || '0';
    },
    devicesCount() {
      return (
        this.getLprHitAlerts.length
        + this.getSensorAlerts.length
        + this.getBusAlerts.length
        + this.getDeviceAlerts.length
        + this.getRapidsosAlerts.length || '0'
      );
    },
    hitCount() {
      return this.getHitAlerts.length || '0';
    },
    reportantsCount() {
      return this.getReportantAlerts.length || '0';
    },
    incidentsItems() {
      return _.sortBy(this.getIncidentAlerts, (item) => new Date(item.createdAt)).reverse();
    },
    devicesAlerts() {
      const devices = {};
      const grouped = _.groupBy(this.getDeviceAlerts, 'id')
      Object.keys(grouped).forEach((key) => {
        // console.log({ grouped, key })
        devices[key] = grouped[key].splice(0, 1)[0]
        devices[key].devices = grouped[key]
      })
      return _.toArray(devices);
    },
    sosCallItems() {
      return _.sortBy(this.getSOSCallAlerts, (item) => new Date(item.createdAt)).reverse();
    },
    reportantsItems() {
      return _.sortBy(this.getReportantAlerts, (item) => {
        return new Date(parseInt(item?.lastPosition?.additionalData?.call_start_time || '0'))
      }).reverse();
    },
    devicesItems() {
      return _.sortBy(
        [
          ...this.getLprHitAlerts,
          ...this.getSensorAlerts,
          ...this.getExternalSensorAlerts,
          ...this.getBusAlerts,
          ...this.devicesAlerts,
          ...this.getRapidsosAlerts,
        ],
        '_addedAt'
      ).reverse();
    },
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('alerts', [
      'getLprHitAlerts',
      'getSensorAlerts',
      'getExternalSensorAlerts',
      'getIncidentAlerts',
      'getReportantAlerts',
      'getBusAlerts',
      'getDeviceAlerts',
      'getHitAlerts',
      'getRapidsosAlerts',
      'getSOSCallAlerts'
    ]),
  },
  watch: {
    tab(newTab, oldTab) {
      this.$parent.$emit('cambio-tab', this.tab);
    }
  },
};
</script>
<style lang="css" scoped>
.hidebutton {
  width: 100%;
  text-align: right;
  padding: 4px, 16px;
  opacity: 1;
}

.v-list-item {
  min-height: 48px !important;
}

.v-list-item__action,
.v-list-item__avatar,
.v-list-item__icon {
  min-width: unset !important;
  align-self: unset !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px !important;
}
</style>

<style lang="css">
.tab-alert .v-slide-group__prev {
  flex: unset !important;
  min-width: unset !important;
}

.tab-alert .v-slide-group__next {
  flex: unset !important;
  min-width: unset !important;
}
</style>
