<template>
  <div class="murphy-div">
    <div class="d-flex px-4 pt-2">
      <v-text-field class="mt-2" label="Número de placa" v-model="placa" dark :error="error ? true : false"
        :error-messages="error" :loading="loading" :disabled="loading" @keyup.enter="buscarPlaca"></v-text-field>
      <v-text-field class="mt-2 ml-2" label="Serie" v-model="serie" dark :error="errorSerie ? true : false"
        :error-messages="errorSerie" :loading="loading" :disabled="loading" @keyup.enter="buscarSerie"></v-text-field>
      <div class="pl-4" style="height: 80px; width: 80px;">
        <button type="button" @click="buscarVehiculo">
          <img :src="murphyLogo" style="height: 80%; width: 70%; object-fit: contain" alt="Murphy" title="buscar" />
        </button>
      </div>
    </div>
    <v-card class="mx-auto" outline dark height="calc(100% - 385px)" color="transparent" style="positon: relative"
      v-if="data">
      <v-list-item three-line>
        <v-list-item-content>
          <div class="pb-2 d-flex justify-md-space-between">
            <div>
              <div class="data-title">Placa</div>
              <div>{{ getPlaca() }}</div>
            </div>
            <div>
              <div class="data-title">Estado</div>
              <div>{{ getEstado() }}</div>
            </div>
            <div>
              <div :class="(data.reporte != 'SIN REPORTE' ? 'red' : 'success') + '--text'">
                {{ getReporteRobo() }}
              </div>
            </div>
          </div>
          <div class="mt-2 pb-2">
            <div class="data-title">Dueño</div>
            <div>{{ getDueno() }}</div>
          </div>
          <div class="mt-2 pb-2 d-flex justify-md-space-between">
            <div>
              <div class="data-title">Modelo</div>
              <div>{{ getMarca() }}</div>
            </div>
          </div>
          <div class="mt-2 pb-2 d-flex justify-md-space-between">
            <div>
              <div class="data-title">Color</div>
              <div>{{ getColor() }}</div>
            </div>
            <div>
              <div class="data-title">Tarjeta</div>
              <div>{{ getTarjeta() }}</div>
            </div>
            <div>
              <div class="data-title">Serie</div>
              <div>{{ getSerie() }}</div>
            </div>
          </div>
          <div class="mt-2 pb-2">
            <div class="data-title">Domicilio</div>
            <div>{{ getDomicilio() }}</div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Arco</th>
              <th>Sentido</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lpritem in lprdata" :key="lpritem.id">
              <td class="caption" style="padding-left: 3px; padding-right: 3px;">{{ getLprPropertie(lpritem, 'registro') }}</td>
              <td class="caption">{{ getLprPropertie(lpritem, 'arco') }}</td>
              <td class="caption">{{ getLprPropertie(lpritem, 'sentido') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import murphyLogo from '@/assets/murphy.png'
import { mapGetters } from 'vuex';
import { MurphyService } from '../../../util/murphy.service'

export default {
  props: {
  },
  mounted() {
  },
  data() {
    return {
      show: true,
      tab: 0,
      loading: false,
      placa: '',
      serie: '',
      error: '',
      errorSerie: '',
      data: null,
      lprdata: [],
      murphyLogo,
      actions: [{
        icon: 'mdi-triangle-outline mdi-rotate-270',
        help: 'Ocultar',
        action: this.toggle
      }]
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
    getLprPropertie(item, propertie) {
      if (Array.isArray(item)) {
        const obj = item.find((element) => {
          if (element?.name === propertie) {
            return 1
          }
          return 0;
        })
        if (obj) {
          return obj?.value
        }
      }
      return '------'
    },
    getPlaca() { return this.data.placa },
    getEstado() { return this.data.estado },
    getReporteRobo() { return this.data.reporte || '---------------' },
    getDueno() { return this.data.propietario || '------' },
    getMarca() { return this.data.vehiculo || '------' },
    getLinea() { return this.data.LINEA || '------' },
    getColor() { return this.data.color || '------' },
    getClase() { return this.data.CLASE_VEHICULO || '------' },
    getTipo() { return this.data.TIPO_VEHICULO || '------' },
    getModelo() { return this.data.MODELO || '------' },
    getTarjeta() { return this.data.tarjeta || '------' },
    getSerie() { return this.data.serie || '------' },
    getDomicilio() { return (this.data.domicilio) || '------' },
    buscarSerie() {
      const murphyUser = this.getUser?.attributes?.consulta_murphy;
      if (!murphyUser) {
        window.VMA.showError({ title: 'No tienes permisos para realizar esta consulta' })
        return;
      }
      this.loading = true
      const murphyService = new MurphyService();
      murphyService.createQuery('VehiculosSerie', murphyUser, { placa: this.serie }).then((value) => {
        if (value?.queryObjects?.length > 0) {
          this.data = value.queryObjects[0].properties
          this.error = ''
          this.errorSerie = ''
          this.placa = ''
          this.serie = ''
        } else {
          this.errorSerie = `No se encontró la serie "${this.serie}"`
        }
      }).catch((err) => {
        console.log('ERROR', err)
        window.VMA.showError({ title: 'Ocurrió un error en la consulta' })
      }).finally(() => {
        this.loading = false
      })
    },
    buscarVehiculo() {
      if (this.serie) {
        this.buscarSerie()
      } else {
        this.buscarPlaca()
      }
    },
    buscarPlaca() {
      const murphyUser = this.getUser?.attributes?.consulta_murphy;
      if (!murphyUser) {
        window.VMA.showError({ title: 'No tienes permisos para realizar esta consulta' })
        return;
      }
      this.loading = true
      const murphyService = new MurphyService();
      murphyService.createQuery('VehiculosPlaca', murphyUser, { placa: this.placa }).then((value) => {
        console.log('VALUE', value)
        if (value?.queryObjects?.length > 0) {
          this.data = value.queryObjects[0].properties
          this.lprdata = []
          for (let i = 0; i < value.queryObjects[0].extendedData?.length; i++) {
            const element = value.queryObjects[0].extendedData[i];
            if (element?.srcName === 'ALPR PLACA') {
              this.lprdata.push(element?.properties)
            }
          }
          console.log('LPRDATA', this.lprdata)
          this.error = ''
          this.errorSerie = ''
          this.placa = ''
          this.serie = ''
        } else {
          this.error = `No se encontró la placa "${this.placa}"`
        }
      }).catch((err) => {
        console.log('ERROR', err)
        window.VMA.showError({ title: 'Ocurrió un error en la consulta' })
      }).finally(() => {
        this.loading = false
      })
    },
    copy() {
      if (this.data) {
        const text = [
          'Placa: ' + this.getPlaca(),
          'Estado: ' + this.getEstado(),
          'Reporte: ' + this.getReporteRobo(),
          'Dueño: ' + this.getDueno(),
          'Modelo: ' + this.getMarca(),
          'Color: ' + this.getColor(),
          'Tarjeta: ' + this.getTarjeta(),
          'Serie: ' + this.getSerie(),
          'Domicilio: ' + this.getDomicilio()
        ].join('\n')
        window.navigator.clipboard.writeText(text);
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
}
</script>
<style lang="scss" scoped>
.data-title {
  font-size: .6rem;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.hidebutton {
  width: 100%;
  text-align: left;
  padding: 4px, 16px;
  opacity: 1;
}

.murphy-div {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.v-list-item__content>*:not(:last-child) {
  border-bottom: 1px dashed lightslategrey;
}

.v-list-item__subtitle {
  user-select: text;
}

.v-list-item__title {
  user-select: text;
}
</style>
