import { emergencySubTypes } from '../data/motivosReporteCadw';

export default {
  _id: {
    type: String,
    visibility: false
  },
  type: {
    label: 'Tipo',
    type: String,
    options: emergencySubTypes
  },
  description: {
    label: 'Descripción',
    type: String,
    textarea: true,
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude']
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false
  },
  municipality: {
    label: 'Municipio',
    type: String,
    defaultValue: 'TOLUCA',
  },
};
