<template>
  <my-window
    :title="getName()"
    :width="width"
    :height="height"
    :top="top"
    :left="left"
    :camera="false"
    :actions="actions"
    :onClose="onClose"
    ref="camWindow"
  >
    <div class="camdiv">
      <iframe
        class="camdiv"
        :title="type"
        :src="source"
        :id="camera"
      ></iframe>
      <div style="position: absolute; bottom: 40px; left: 8px; height: 100px; width: 100px; opacity: 0.7" v-if="type === 'pmi' && cameraobj.isPtz && getUser.attributes.ptz_control">
        <div class="d-flex justify-center">
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            :disabled="moveup"
            @click="cameraMovement('moveup')"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
        <div class="d-flex justify-space-between">
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            :disabled="moveleft"
            @click="cameraMovement('moveleft')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            :disabled="moveright"
            @click="cameraMovement('moveright')"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            :disabled="movedown"
            @click="cameraMovement('movedown')"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </div>
      <div style="position: absolute; top: 48px; left: 8px; height: 100px; width: 150px; opacity: 0.8" v-if="type === 'pmi' && cameraobj.isPtz">
        <div class="d-flex flex-column justify-space-between">
          <v-btn
            class="mb-2"
            color="secondary"
            fab
            x-small
            dark
            :disabled="zoomin"
            @click="cameraMovement('zoomin')"
          >
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            :disabled="zoomout"
            @click="cameraMovement('zoomout')"
          >
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </my-window>
</template>
<script>
import Window from '@/components/Window.vue';
import ApiService from '@/util/api.service';
import { mapGetters } from 'vuex'

export default {
  components: {
    'my-window': Window
  },
  props: {
    item: Object,
    camera: String,
    type: String,
    num: Number,
    top: Number,
    left: Number,
    onClose: Function,
    actions: Array,
    width: {
      type: Number,
      default: 480
    },
    height: {
      type: Number,
      default: 320
    }
  },
  data() {
    return {
      cameraobj: {},
      moveup: false,
      movedown: false,
      moveleft: false,
      moveright: false,
      zoomin: false,
      zoomout: false
    }
  },
  created() {
    if (this.type !== 'sos') {
      this.cameraobj = JSON.parse(atob(this.camera))
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    source() {
      if (this.type === 'sos') {
        return '/' + this.item.visualization.split('//')[2]
      }
      const cameraObj = this.cameraobj
      const item = this.item
      return `/api/v2/video/stream/${cameraObj?.server}/${item.uniqueId}/${cameraObj?.name}`
    }
  },
  methods: {
    getName() {
      if (this.num !== undefined) {
        if (this.cameraobj.isPtz && this.getUser.attributes.ptz_control) {
          return `<i aria-hidden="true" class="v-icon notranslate mdi mdi-camera-control theme--dark"></i> ${this.item.name} (${this.num})`
        }
        return `<i aria-hidden="true" class="v-icon notranslate mdi mdi-video theme--dark"></i> ${this.item.name} (${this.num})`
      }
      return this.item.name
    },
    cameraMovement(action) {
      this[action] = true
      const numcam = this.num - 1
      ApiService({
        url: '/cameras/ptz/' + action,
        method: 'get',
        params: {
          uniqueid: this.item.uniqueId,
          camname: 'dev' + numcam
        }
      }).then(() => {
        this[action] = false
      }).catch((err) => {
        console.error(err)
        this[action] = false
      })
    },
    close() {
      this.$refs.camWindow.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  .camdiv {
    vertical-align: middle;
    height: 100%;
    width: 100%;
    border: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .colaso {
    text-align: center;
    padding: 5px;
    border: 1px solid white;
  }
  .titlebar {
    padding: 0em 0.5em !important;
  }
</style>
