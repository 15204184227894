import mapImages from '@/util/mapImages';
import Util from '@/util';
import BaseStore from './baseStore';

const ol = window.ol;

class ExternalSensorStore extends BaseStore {
  constructor(map, source) {
    super(map, source);
    this.hoveredId = null;
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN',
      name: 'STRING',
    };
    this.sensorsCounter = {
      sensorOnlineCount: 0,
      sensorOfflineCount: 0,
    };
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([item.longitude, item.latitude])),
      name: item.id,
    });
    item.typeOfItem = item.systemName;
    marker.setId('sensors__' + item.id);
    marker.set('record', item);
    this.markers.set(item.id, marker);
    if (item.status === 'up') {
      this.sensorsCounter.sensorOnlineCount++;
    } else {
      this.sensorsCounter.sensorOfflineCount++;
    }
    this._updateMarker(item);
    return marker;
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id);
    item.statusTitle = Util.statusMap[item.status];
    tmpMarker.setStyle(this._getStyle(item));
    const record = tmpMarker.get('record');
    if (record.status === 'up' && item.status === 'down') {
      this.sensorsCounter.sensorOnlineCount--;
      this.sensorsCounter.sensorOfflineCount++;
    }
    if (record.status === 'down' && item.status !== 'down') {
      this.sensorsCounter.sensorOnlineCount++;
      this.sensorsCounter.sensorOfflineCount--;
    }
    if (item.latitude !== record.latitude || item.longitude !== record.longitude) {
      tmpMarker
        .getGeometry()
        .setCoordinates(window.ol.proj.fromLonLat([item.longitude, item.latitude]));
    }
    const visible = this._isVisible(item);

    Object.assign(record, item);
    this._updateVisbility(tmpMarker, visible);
  }

  updateMarker(item) {
    if (item.additionalData.status === 'discarded' || item.additionalData.status === 'closed') {
      if (this.markers.get(item.id)) {
        this.olSource.removeFeature(this.markers.get(item.id));
        this.markers.delete(item.id);
        if (window.VMA.mapComponent.externalSensorsRouteStore.selectedItem?.id === item.id) {
          window.VMA.mapComponent.externalSensorsRouteStore.cleanMarkers();
        }
      }
      return null;
    }
    if (!this.markers.get(item.id)) {
      return this._createMarker(item);
    }
    this._updateMarker(item);
    return null;
  }

  _getStyle(item, hover = false) {
    const category = mapImages[item.systemName.toLowerCase()] || mapImages.sensor;
    console.log(category)
    return this._getIcon(category.icon, item.name, {
      scale: !hover ? category.scale : category.scale + 0.1,
      offsetY: category.offsetY,
      offsetX: category.offsetX,
    });
  }

  _getStyleSelected(item) {
    return [
      this._getStyle(item, true)
    ]
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId);
      marker.setStyle(this._getStyle(marker.get('record')));
      this.hoveredId = null;
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id);
      marker.setStyle(this._getStyleSelected(marker.get('record')));
      this.hoveredId = id;
    }
  }

  async updateMarkersStyle() {
    this.markers.forEach(async (marker) => {
      marker.setStyle(this._getStyle(marker.get('record')));
    });
  }
}

export default ExternalSensorStore;
