<template>
  <transition name="slide-fade">
    <v-toolbar
      class="map-toolbar"
      v-hotkey="keymap"
      v-show="show"
      dark
    >
      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            @click="selectKPI"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-chart-donut-variant</v-icon>
          </v-btn>
        </template>
        <span>KPI</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            :class="{ primary: show_live_route }"
            @click="toggleShowLiveRoute"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-map-marker-path</v-icon>
          </v-btn>
        </template>
        <span>ESTELA DE RECORRIDO</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            @click="toggleShowTables"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-table-large</v-icon>
          </v-btn>
        </template>
        <span>LISTADO DE DISPOSITIVOS</span>
      </v-tooltip>
      <v-tooltip bottom v-if="searchBar === 'places'">
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            v-on='on'
            @click="changeSearchBar"
          >
            <v-icon>mdi-map-search</v-icon>
          </v-btn>
        </template>
        <span>BUSCAR UNIDADES</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            v-on='on'
            @click="changeSearchBar"
          >
            <v-icon>mdi-database</v-icon>
          </v-btn>
        </template>
        <span>BUSCAR LUGARES</span>
      </v-tooltip>

      <div
        class="v-input v-input--hide-details v-text-field v-text-field--single-line
          v-text-field--is-booted"
        v-show="searchBar === 'places'"
        style="width: 500px"
      >
        <div class="v-input__control">
          <div class="v-input__slot">
            <div class="v-text-field__slot">
              <label
                class="v-label v-label--active"
                style="left: 0px; right: auto; position: absolute;"
              >
                Dirección o lugar
              </label>
              <input ref="placeAutocomplete" style="color: white;">
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="searchBar === 'devices'"
        style="width: 500px"
      >
        <v-autocomplete
          v-model="model"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          clearable
          hide-no-data
          hide-details
          item-text="name"
          return-object
          label="Buscar unidades"
          flat
          @change="onChange"
          @keydown.tab="changeSearchBar"
          ref="searchDevices"
        >
          <template v-slot:item="{ item }">
            <v-list-item-icon
              color="indigo"
              class="headline font-weight-light white--text"
            >
              <v-icon v-if="item.typeOfItem === 'cameras'">mdi-camera</v-icon>
              <v-icon v-else-if="item.typeOfItem === 'buses'">mdi-bus</v-icon>
              <v-icon v-else-if="item.typeOfItem === 'lprs'">mdi-numeric</v-icon>
              <v-icon v-else-if="item.typeOfItem === 'devices'">mdi-devices</v-icon>
              <v-icon v-else-if="item.typeOfItem === 'sensors'">$sensor</v-icon>
              <v-icon v-else-if="item.typeOfItem === 'incidents'">mdi-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>

      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            v-on='on'
            @click="$emit('map-center')"
          >
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-btn>
        </template>
        <span>CENTRAR</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            v-on='on'
            @click="toggleShowConfig"
            v-show="!colapsed"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>CONFIGURACION</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            @click="goReports"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-file-chart</v-icon>
          </v-btn>
        </template>
        <span>IR A REPORTES</span>
      </v-tooltip>

      <v-tooltip bottom v-if="getUser && getUser.administrator">
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            @click="goHome"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-view-dashboard</v-icon>
          </v-btn>
        </template>
        <span>IR A ADMINISTRACIÓN</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            @click="handleFullScreen"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>PANTALLA COMPLETA</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <v-btn
            icon
            @click="handleLogut"
            v-on='on'
            v-show="!colapsed"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>CERRAR SESIÓN</span>
      </v-tooltip>
      <v-menu offset-y dark>
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator='{ on: onTooltip }'>
              <v-btn
                icon
                v-on="{...onMenu, ...onTooltip}"
                v-show="colapsed"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <span>MAS OPCIONES</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item @click="toggleShowLiveRoute" :class="{ primary: show_live_route }">
            <v-list-item-icon>
              <v-icon>mdi-map-marker-path</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>ESTELA DE RECORRIDO</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toggleShowTables">
            <v-list-item-icon>
              <v-icon>mdi-table-large</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>LISTADO DE DISPOSITIVOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toggleShowConfig">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>CONFIGURACION</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goReports">
            <v-list-item-icon>
              <v-icon>mdi-file-chart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>IR A REPORTES</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goHome">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>IR A ADMINISTRACIÓN</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="handleFullScreen">
            <v-list-item-icon>
              <v-icon>mdi-fullscreen</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>PANTALLA COMPLETA</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="handleLogut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>CERRAR SESIÓN</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Util from '@/util'

export default {
  props: {
    setPlace: Function,
    findItems: Function,
    selectCamera: Function,
    selectLpr: Function,
    selectBus: Function,
    selectDevice: Function,
    selectIncident: Function,
    selectSensor: Function,
    getCenter: Function
  },
  data() {
    return {
      show: true,
      isLoading: false,
      items: [],
      model: null,
      search: null,
      searchBar: 'devices',
      colapsed: false,
      resizeObserver: null
    }
  },
  created() {
    this.observeWidth()
  },
  mounted() {
    const center = this.getCenter()
    const defaultBounds = {
      north: center[1] + 0.2,
      south: center[1] - 0.2,
      east: center[0] + 0.2,
      west: center[0] - 0.2,
    };

    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.$refs.placeAutocomplete,
      {
        componentRestrictions: { country: 'mx' },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        strictBounds: false,
        bounds: defaultBounds
      }
    );
    this.autocomplete.addListener('place_changed', () => {
      this.setPlace(this.autocomplete.getPlace())
    })
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    ...mapActions('map', [
      'toggleShowLiveRoute',
      'toggleShowConfig',
      'toggleShowTables'
    ]),
    async handleLogut() {
      if (window.confirm('Estás seguro de cerrar sesión?')) {
        this.$store.dispatch('auth/logout');
      }
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    goHome() {
      this.$router.push({ name: 'home' })
    },
    goReports() {
      this.$router.push({ name: 'reports' })
    },
    toggle() {
      this.show = !this.show
    },
    focusSearch() {
      if (this.searchBar === 'devices') {
        this.$refs.searchDevices.focus();
      } else {
        const center = this.getCenter()
        const bounds = {
          north: center[1] + 0.2,
          south: center[1] - 0.2,
          east: center[0] + 0.2,
          west: center[0] - 0.2,
        };
        this.autocomplete.setBounds(bounds)

        this.$refs.placeAutocomplete.focus();
      }
    },
    selectKPI() {
      this.$emit('select-kpi')
    },
    changeSearchBar(event) {
      if (event) {
        event.preventDefault()
      }
      this.searchBar = this.searchBar === 'devices' ? 'places' : 'devices'
    },
    onChange(val) {
      if (!val) return;
      if (val.typeOfItem === 'cameras') {
        this.selectCamera(val, true)
      } else if (val.typeOfItem === 'lprs') {
        this.selectLpr(val, true)
      } else if (val.typeOfItem === 'buses') {
        this.selectBus(val, true)
      } else if (val.typeOfItem === 'devices') {
        this.selectDevice(val, true)
      } else if (val.typeOfItem === 'incidents') {
        this.selectIncident(val, true)
      } else if (val.typeOfItem === 'sensors') {
        this.selectSensor(val, true)
      }
      this.model = null
    },
    observeWidth() {
      this.resizeObserver = new ResizeObserver(() => {
        this.colapsed = document.getElementById('map').clientWidth < 1200
      })
      this.resizeObserver.observe(document.getElementById('map'));
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapState('map', [
      'show_live_route'
    ]),
    keymap() {
      return {
        'ctrl+space': this.toggle,
        'ctrl+alt+space': this.focusSearch
      }
    }
  },
  watch: {
    search(val) {
      if (!val) return
      this.isLoading = true
      this.items = this.findItems(val)
      this.isLoading = false
    },
    searchBar() {
      setTimeout(this.focusSearch, 150)
    },
    colapsed(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .map-toolbar {
    position: absolute;
    top: var(--map-widget-padding);
    right: var(--map-widget-padding);
    opacity: 0.8;
    width: 50%;
  }

  /* Las animaciones de entrada y salida pueden usar */
  /* funciones de espera y duración diferentes.      */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    // transform: translateY(-20px);
    opacity: 0;
  }

</style>
